import { useEffect } from "react";
import { dayTasks } from "../../utilities/data";
import { toggleActiveClass } from "../../utilities/utilities";
const CalendarTaskItem = () => {
    const darwDayTasksUi = dayTasks.map((task, index) => {
        return (
            <div key={index} className="d-flex-center p-1 mb-3" style={{ userSelect: "none" }}>
                <div className="pseudo-time position-relative fw-bold h-100" style={{ padding: '20px 10px', width: '10%', color: 'var(--bs-link-color)' }}>
                    <p>{task.time}</p>
                </div>
                <div className="d-flex align-items-center p-2 time-line-task" style={{ width: '90%', backgroundColor: 'var(--bg-color)', borderRadius: '7px' }}>
                    <p style={{ opacity: '0.6', fontSize: '14px', padding: '10px 20px 10px 20px', borderLeft: '2px solid var(--bs-link-color)' }}>
                        Type
                        <br />
                        Subject
                    </p>
                    <h4 style={{ fontSize: '17px', lineHeight: '1.8' }} className="ps-3 fw-bold">
                        {task.type}
                        <br />
                        {task.subject}
                    </h4>
                </div>
            </div>
        )
    });
    useEffect(() => {
        toggleActiveClass(document.querySelectorAll(".time-line-task"), "active_task");
    });
    return darwDayTasksUi
}

export default CalendarTaskItem