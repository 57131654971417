import { useState } from "react";
import ProjectCard from "./ProjectCard";

const Projects = () => {
    const [buttonState, setButtonState] = useState(false);
    const darwCard = [1, 2, 3, 4].map(card => <ProjectCard key={card} buttonState={buttonState} />);
    return (
        <div className="project-wrapper
        mt-4 mb-4 p-2">
            <div className="d-flex-between d-flex">
                <h1 className="fw-bold text-white">Project</h1>
                <div>
                    <button onClick={() => setButtonState(false)} className={`p-2 ms-2 text-black-50 fw-bold going-btn ${!buttonState && "active_toggle_btn"}`} style={{ transition: "var(--transition)", borderRadius: "5px", border: "none", outline: "none" }}>Ongoing</button>
                    <button onClick={() => setButtonState(true)} className={`p-2 ms-2 text-black-50 fw-bold pending-btn ${buttonState && "active_toggle_btn"}`} style={{ transition: "var(--transition)", borderRadius: "5px", border: "none", outline: "none" }}>Pending</button>
                </div>
            </div>
            <div style={{ transition: "var(--trasnition)" }} className="going d-flex d-flex-between flex-wrap">
                {darwCard}
            </div>
        </div>
    )
}

export default Projects;