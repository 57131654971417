import { useState } from "react";
import SettingSecurity from "./SettingSecurity/SettingSecurity";
import SettingUsers from "./SettingContent/SettingUsers/SettingUsers";
const SettingContent = () => {
    const [activeUser, setActiveUser] = useState(true);

    return (
        <div className="container mb-3 p-0">
            <div className="p-3 users">
                <h1 className="fw-bolder text-white mb-3">Settings</h1>
                <div className="d-flex align-items-center text-white-50 mb-3" style={{ borderBottom: '1px solid #777', backgroundColor: 'var(--main-color)' }}>
                    <h4 className={`users-btn fw-bold p-3 setting-nav ${activeUser && "active_Item"}`} onClick={() => setActiveUser(true)} style={{ fontSize: '13px', cursor: 'pointer', marginLeft: '15px !important' }}>Users</h4>
                    <h4 className={`security-btn fw-bold p-3 setting-nav ${!activeUser && "active_Item"}`} onClick={() => setActiveUser(false)} style={{ fontSize: '13px', cursor: 'pointer', marginLeft: '15px !important' }}>Security</h4>
                </div>
            </div>
            {activeUser && <SettingUsers />}
            {!activeUser && <SettingSecurity />}
        </div>
    );
}

export default SettingContent;