import ChartUi from "./ChartUi";

const ProjectMetricsDashboard = () => {
    return (
        <div className="mt-2">
            <div className="col-lg-12 d-flex d-flex-between flex-wrap ">
                <div className="mb-3 d-flex-center">
                    <div className="d-flex-center me-3" style={{ width: '30px', height: '30px', borderRadius: '50px', backgroundColor: 'var(--second-color)' }}>
                        <span className="text-white">
                            <i className="fa-solid fa-broom" style={{ fontSize: '13px' }} />
                        </span>
                    </div>
                    <p className="text-white-50 fw-bold" style={{ minWidth: '100px !important', fontSize: '13px' }} >
                        24 in Progress
                    </p>
                </div>
                <div className="mb-3 d-flex-center">
                    <div className="d-flex-center me-3" style={{
                        width: '30px', height: '30px', borderRadius: '50%', backgroundColor: 'var(--second-color)'
                    }}>
                        <span className="text-white">
                            <i className="fa-solid fa-rotate-right" style={{ fontSize: '13px' }}></i>
                        </span>
                    </div>
                    <p className="text-white-50 fw-bold"
                        style={{ fontSize: '13px', minWidth: '100px !important' }}> 45 Total Projects
                    </p>
                </div>
                <div className="mb-3 d-flex-center">
                    <div className="d-flex-center me-3" style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: 'var(--second-color)' }}>
                        <span className="text-white">
                            <i className="fa-solid fa-spinner" style={{ fontSize: '13px' }}></i>
                        </span>
                    </div>
                    <p className="text-white-50 fw-bold" style={{ fontSize: '13px', minWidth: '100px !important' }}> 12 upcoming </p>
                </div>
                <div className="col-lg-12" style={{ backgroundColor: '#e2f7ff' }}>
                    <div className="chart position-relative">
                        <ChartUi />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectMetricsDashboard;