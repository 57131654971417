const AccountSecurityPanel = () => {
    return (
        <div id="security" className="p-3 mb-3 setting-media"
            style={{ minHeight: 372, borderRadius: 10, backgroundColor: 'var(--main-color)', width: 'calc(97% / 3)' }}>
            <h3 className="fw-bolder mb-2">Security Info</h3>
            <p style={{ fontSize: 11 }} className="fw-bold text-white-50">Security Information About Your Account</p>
            <div className="pt-3 pb-3 d-flex-between d-flex" style={{ borderBottom: '1px dashed #777' }}>
                <div>
                    <h4 className="mb-1 fw-bold">Password</h4>
                    <p className="text-white-50 fw-bold" style={{ fontSize: 13 }}>Last Change On 25/10/2022</p>
                </div>
                <button className="main-btn">Change</button>
            </div>
            <div className="pt-3 pb-3 d-flex-between d-flex" style={{ borderBottom: '1px dashed #777' }}>
                <div>
                    <h4 className="mb-1 fw-bold">Two-Factor Authentication</h4>
                    <p className="text-white-50 fw-bold" style={{ fontSize: 13 }}>Enable/Disable The Feature</p>
                </div>
                <label>
                    <input className="toggle-checkbox" defaultChecked type="checkbox" />
                    <div className="toggle-switch"></div>
                </label>
            </div>
            <div className="pt-3 pb-3 mb-4 d-flex-between d-flex">
                <div>
                    <h4 className="mb-1 fw-bold">Devices</h4>
                    <p className="text-white-50 fw-bold" style={{ fontSize: 13 }}>Check The Login Devices List</p>
                </div>
                <button className="main-btn">Change</button>
            </div>
        </div>
    )
}
export default AccountSecurityPanel;