import ProjectHeader from "./ProjectHeader";
import ViewProjectSection from "./ViewProjectSection";

const ProjectCard = ({ buttonState }) => {

    return (
        <div className="p-2 mt-3 mb-3 going-card" style={{
            width: 'calc(49%)', backgroundColor: 'var(--main-color)', borderRadius: '7px'
        }}>
            <ProjectHeader buttonState={buttonState} />
            <ViewProjectSection />
        </div>
    )
}

export default ProjectCard;