import { Fragment } from "react";
import OverallProgress from "./OverallProgress/OverallProgress";
import HomeChat from "./HomeChat/HomeChat";
import TeamActivity from "./TeamActivity/TeamActivity";
import LatestTasks from "./LatestTasks/LatestTasks";
import { teamData } from "../../../utilities/data";

const HomeSideBar = () => {

    const darwTeamUi = teamData.map((person , index) => <TeamActivity key={index} name={person.name} role={person.role} />)
    return (
        <Fragment>
            <OverallProgress />
            <HomeChat />
            {darwTeamUi}
            <LatestTasks /> 
        </Fragment>
    )
}

export default HomeSideBar;