const ProfileBox = () => {
    return (
        <div className="profile-info p-2 mb-5">
            <div className="text-center">
                <img src={require("./nabowy.PNG")} style={{ maxWidth: '100%' }} alt="my-photo" width="200px" height="180px" />
            </div>
            <div className="text-center mb-3">
                <h2 className="fw-bold" style={{ fontSize: '15px' }}>Mahmoud Nabowy</h2>
                <p style={{ fontSize: '10px' }}>nabowy.work@gmail.com</p>
            </div>
            <div>
                <p style={{ fontSize: '13px', fontWeight: 'bold' }} className="text-white-50 text-center">
                    Front-end Developer
                    <br />
                    Some Knowledge For UX/UI Designer
                </p>
            </div>
        </div>
    );
}

export default ProfileBox;