import Header from "../Header/Header";
import HomeContent from "./HomeContent/HomeContent";

const Home = () => {
    return (
        <div className="page-sections">
            <Header />
            <HomeContent />
        </div>
    )
}

export default Home;