import { SwiperSlide } from "swiper/react";
import { person_Names } from "../../../../utilities/data";

const SlideImage = ({imgSrc , index}) => {
    return (
            <div className="wrapper-img d-flex flex-column me-2 align-items-center fw-bold" style={{ fontSize: "11px" }}>
                <img src={imgSrc} width="30px" height="30px" alt="img" style={{ marginBottom: '5px !important', borderRadius: '50%', border: '2px solid #f4782e', display: 'block' }}></img>
                <span className="d-block fw-bold" style={{ fontSize: "12px" }}>{person_Names[Math.floor(Math.random() * person_Names.length)]}</span>
            </div>
    )
}

export default SlideImage;