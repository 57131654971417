import { images } from "../../../../utilities/data";
import MetricIcons from "./MetricIcons";

const TeamActivity = ({ name, role }) => {
    return (
        <div className="p-3 mb-3 text-white" style={{ backgroundColor: 'var(--main-color)', borderRadius: '10px' }}>
            <h1 className="text-white fw-bold" style={{ fontSize: "20px" }}>Team activity</h1>
            <div className="mb-3" style={{ borderBottom: '2px dashed #333' }}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center col-lg-8 mt-3 mb-3">
                        <img src={images[Math.floor(Math.random() * images.length)]}
                            className="me-3" style={{ width: '50px', height: '50px', borderRadius: '50%' }} alt="img" />
                        <div>
                            <h2 className="fw-bold" style={{ fontSize: '14px' }}>{name}</h2>
                            <p className="text-white-50 fw-bold" style={{ fontSize: '11px' }}>{role}</p>
                        </div>
                    </div>
                    <h4 className="fw-bold text-white-50 mt-3 mb-3" style={{ fontSize: '13px' }}>5 min ago</h4>
                </div>
                <p className="fw-bold ms-4 mb-2 mt-2" style={{ fontSize: '13px' }}>How Can i Buy only the design</p>
                <MetricIcons />
            </div>
        </div>
    )
}

export default TeamActivity;