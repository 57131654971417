const ChatMessage = () => {
    return (
        <div className="chat-wrapper d-flex flex-column">
            <div className="d-flex-center p-1 mb-2"
                style={{ boxShadow: '0px 0px 20px 20px #575f6e38 inset', borderRadius: '5px', width: 'fit-content', maxWidth: '90%' }}>
                <p style={{ fontWeight: 400, opacity: 0.2, fontSize: '12px', width: '85%' }}>Hi, i have great news. A new project is starting, I'll tell you more about it later</p>
                <span className="text-white-50 fw-bold me-2" style={{ opacity: 0.4, width: '10%', fontSize: '11px', alignSelf: 'flex-end' }}>8:29</span>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ boxShadow: '0px 0px 20px 20px #575f6e38 inset', borderRadius: '5px', width: 'fit-content', maxWidth: '90%' }}>
                <p style={{ fontWeight: 400, opacity: 0.2, fontSize: '12px', width: '85%' }}>it's a new neobank with great deposit terms</p>
                <span className="text-white-50 fw-bold me-2" style={{ opacity: 0.4, width: '10%', fontSize: '11px', alignSelf: 'flex-end' }}>8:30</span>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ boxShadow: '0px 0px 20px 20px #575f6e38 inset', borderRadius: '5px', width: 'fit-content', maxWidth: '90%' }}>
                <p style={{ fontWeight: 400, opacity: 0.2, fontSize: '12px', width: '85%' }}>
                    nabowy.work@gmail.com
                    <br />
                    https://www.nabowy.online
                </p>
                <span className="text-white-50 fw-bold me-2" style={{ opacity: 0.4, width: '10%', fontSize: '11px', alignSelf: 'flex-end' }}>8:30</span>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ boxShadow: "0px 0px 20px 20px #575f6e38 inset", borderRadius: "5px", width: "fit-content", maxWidth: "90%", alignSelf: "flex-end" }}>
                <p style={{ fontWeight: 400, fontSize: "12px", width: "85%" }}>
                    Hi. wow, that's intriguing
                </p>
                <span className="text-white-50 fw-bold "
                    style={{ width: "10%", fontSize: "11px", alignSelf: "flex-end", margin: "0 10px !important" }}>8:31</span>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ boxShadow: "0px 0px 20px 20px #575f6e38 inset", borderRadius: "5px", width: "fit-content", maxWidth: "90%", alignSelf: "flex-end" }}>
                <p style={{ fontWeight: 400, fontSize: "12px", width: "85%" }}>
                    I'm looking forward to the details
                </p>
                <span className="text-white-50 fw-bold "
                    style={{ width: "10%", fontSize: "11px", alignSelf: "flex-end", margin: "0 10px !important" }}>8:31</span>
            </div>
            <div className="mb-2"
                style={{ boxShadow: "0px 0px 180px 20px #575f6e38 inset", borderRadius: "5px", width: "fit-content", maxWidth: "90%" }}>
                <div className="d-flex-center p-1 pe-3 ps-3">
                    <p style={{ fontWeight: 400, opacity: 0.2, fontSize: "12px", width: "85%" }} className="text-center fw-bold pe-3 ps-3">
                        MEETING
                        <br />
                        <br />
                        <span className="mb-2">Jul 28</span>
                        <br />
                        <br />
                        <span className="mb-2">8:40 <sub>pm</sub></span>
                    </p>
                    <span className="text-white-50 fw-bold "
                        style={{ opacity: 0.4, width: "10%", fontSize: "11px", alignSelf: "flex-end", margin: "0 10px !important" }}>8:30</span>
                </div>
                <p className="p-2 p-1 pe-3 ps-3"
                    style={{ borderTop: "1px solid black", fontSize: "13px", fontWeight: "bold" }}>
                    Add to Schedule
                </p>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ boxShadow: "0px 0px 20px 20px #575f6e38 inset", borderRadius: "5px", width: "fit-content", maxWidth: "90%" }}>
                <p style={{ fontWeight: "400", opacity: "0.2", fontSize: "12px", width: "85%" }} className="d-flex-center">
                    <i className="fa-solid fa-play me-2"
                        style={{ padding: "8px", cursor: "pointer", background: "#606878", borderRadius: "50%" }}></i>
                    <span>-||---|||-----|||||--|||--|||</span>
                </p>
                <span className="text-white-50 fw-bold "
                    style={{ opacity: "0.4", width: "10%", fontSize: "11px", alignSelf: "flex-end", margin: "0 10px !important" }}>8:29</span>
            </div>
            <div className="d-flex-center p-1 mb-2"
                style={{ boxShadow: "0px 0px 20px 20px #575f6e38 inset", borderRadius: "5px", width: "fit-content", maxWidth: "90%", alignSelf: "flex-end" }}>
                <p style={{ fontWeight: "400", fontSize: "12px", width: "85%" }}>
                    Okay, I can't wait to get started
                </p>
                <span className="text-white-50 fw-bold "
                    style={{ width: "10%", fontSize: "11px", alignSelf: "flex-end", margin: "0 10px !important" }}>8:31</span>
            </div>
        </div>
    )
}

export default ChatMessage;