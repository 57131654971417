import { NavLink } from "react-router-dom";

const Sidebar = () => {
    return (
        <div className="nav-wrapper" style={{ borderRight: "1px solid var(--main-color)", backgroundColor: "var(--light-color)" }}>
            <h4 className="logo mb-lg-5 mb-md-5 text-white fw-bold text-center d-none d-md-block d-lg-block fst-italic position-relative p-3">
                ProNabowy
            </h4>
            <ul className="link-items">
                <li className="link-item">
                    <NavLink to={""} className="link text-white-50 nav-link">
                        <i className="fa-solid fa-house"></i>
                        <span style={{ '--i': 1 }}>Dashboard</span>
                    </NavLink>
                </li>
                <li className="link-item">
                    <NavLink to={'setting'} className="link text-white-50 nav-link">
                        <i className="fa-solid fa-gear"></i>
                        <span style={{ '--i': 2 }}>Settings</span>
                    </NavLink>
                </li>
                <li className="link-item">
                    <NavLink to={"calendar"} className="link text-white-50 nav-link">
                        <i className="fa-regular fa-calendar-days"></i>
                        <span style={{ '--i': 3 }}>Calendar</span>
                    </NavLink>
                </li>
                <li className="link-item">
                    <NavLink to={"files"} className="link text-white-50 nav-link">
                        <i className="fa-solid fa-folder-open"></i>
                        <span style={{ '--i': 4 }}>Files</span>
                    </NavLink>
                </li>
                <li className="link-item">
                    <NavLink to={"contact"} className="link text-white-50 nav-link">
                        <i className="fa-solid fa-address-card"></i>
                        <span style={{ '--i': 6 }}>Contact</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar;