import { useEffect } from "react";
import { setDateUi, toggleActiveClass } from "../../utilities/utilities";
import EventCalendar from "./EventCalendar";
import CalendarTaskItem from "./CalendarTaskItem";

const CalendarUi = () => {

    useEffect(() => {
        setDateUi();
        toggleActiveClass(document.querySelectorAll("p.day span"), "current_day");
    }, []);
    return (
        <div className="col-lg-8 p-2 p-1 user-select-none" style={{ backgroundColor: "var(--main-color)", borderRadius: "10px" }}>
            <div>
                {<EventCalendar />}
                <div className="time-line p-3 mb-2">
                    <h2 className="fw-bold mb-3">
                        <span className="day-by-week-str me-2" style={{ fontSize: '19px' }}></span>
                        <sub className="today-by-week" style={{ fontSize: '15px' }}></sub>
                    </h2>
                    <div>
                        <CalendarTaskItem />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalendarUi;