import { tasksData } from "../../../../utilities/data";
import LatestTasksItem from "./LatestTasksItem";

const LatestTasks = () => {

    const renderLatestTasks = tasksData.map((task, index) => <LatestTasksItem key={index} task={task} />);
    return (
        <div className="p-3 mb-3 text-white" style={{ backgroundColor: 'var(--main-color)', borderRadius: '10px' }}>
            <h3 className="fw-bold text-center mt-3 mb-3">
                Latest Tasks
            </h3>
            {renderLatestTasks}
        </div>
    )
}

export default LatestTasks;