import DraggableSection from "./DraggableSection/DraggableSection";
import HomeSideBar from "./HomeSideBar/HomeSideBar";
import NewTaskForm from "./NewTaskForm/NewTaskForm";
import Projects from "./Projects/Projects";
import Table from "./Table/Table";

const HomeContent = () => {
    return (
        <section className="p-lg-2 pt-4" style={{ backgroundColor: "var(--bg-color)", position: "relative", marginLeft: "3px !important" }}>
            <div className="container p-0 position-relative">
                <div className="row">
                    <div className="col-lg-8 p-1">
                        <DraggableSection />
                        <Projects />
                        <Table />
                    </div>
                    <div className="col-lg-4 p-2">
                        <HomeSideBar />
                    </div>
                    <NewTaskForm />
                </div>
            </div>
        </section>
    )
}

export default HomeContent