const PendingInvites = () => {
    return (
        <div className="row align-items-center position-relative mt-5">
            <div className="col-lg-4 p-3 mb-3">
                <div className="mb-3">
                    <h3 className="fw-bold mb-4 text-white">Pending Invites</h3>
                    <p style={{ lineHeight: 1.8, fontWeight: 500, fontSize: 15 }} className="text-white-50 fw-bold">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, illum corrupti.
                        Doloremque, est aliquam quis consequatur
                        tempore voluptatem nulla nihil maxime odio ipsam officia in dolore? Id tempora
                        ducimus assumenda!
                    </p>
                </div>
            </div>
            <div className="peopel-invites text-white col-lg-8 mb-3 p-0" style={{ height: 'fit-content', border: '2px solid #24252b' }}></div>
        </div>
    )
}

export default PendingInvites;