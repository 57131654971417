const MetricIcons = () => {
    return (
        <div className="d-flex d-flex-between col-lg-6 ms-4 mb-2 mt-2">
            <div>
                <span className="text-white-50">
                    <i className="fa-brands fa-rocketchat" style={{ fontSize: '11px' }}></i>
                </span>
                <span className="text-white-50 ms-1 fw-bold animait-num" style={{ fontSize: '13px' }}> 34 </span>
            </div>
            <div>
                <span className="text-white-50"><i className="fa-solid fa-heart" style={{ fontSize: '11px' }}></i></span>
                <span className="text-white-50 ms-1 fw-bold  animait-num" style={{ fontSize: '13px' }}> 14 </span>
            </div>
            <div>
                <span className="text-white-50"><i className="fa-solid fa-share-nodes" style={{ fontSize: '11px' }}></i></span>
                <span className="text-white-50 ms-1 fw-bold  animait-num" style={{ fontSize: '13px' }}>3</span>
            </div>
        </div>
    )
}

export default MetricIcons;