const FileCard = ({folder , index}) => {
    return (
        <div key={index} className="text-center text-white me-3 mb-3 pe-2 ps-2"
            style={{ cursor: "pointer", borderRadius: "5px" }}>
            <i className="fa-solid fa-folder mb-2"
                style={{ fontSize: "100px", color: "var(--near-to-yellow)" }}></i>
            <p className="fw-bold">{folder}</p>
        </div>
    )
}

export default FileCard;