import { homeCard, plusCard, inveitePersonUi } from "./dom";
import { messages } from "./data";
let randomNum = () => {
    return Math.floor(Math.random() * 124321432);
}
const getLocalStorageData = (item) => {
    if (localStorage.getItem(item)) {
        return JSON.parse(localStorage.getItem(item));
    }
}
const convertToJson = (array) => {
    return JSON.stringify(array);
};
export const fullscreenImg = (selector) => {
    const images = document.querySelectorAll(selector);
    images.forEach(img => {
        img.style.cssText += "cursor: pointer;";
        img.addEventListener("click", () => {
            img.style.cssText += "border-radius: 0;";
            img.requestFullscreen();
        });
        img.addEventListener("fullscreenchange", () => {
            if (!document.fullscreenElement) {
                img.style.cssText += "border-radius: 50%;";
            }
        })
    });
}
export const animitNum = () => {
    const animaitNumElements = document.querySelectorAll(".animait-num");
    let randomNums = [123, 435, 645, 978, 457, 725, 584];
    let count = 0;


    // Create Interval Function To Set Dynamic Animation
    let interval = setInterval(() => {
        animaitNumFunction(animaitNumElements);
    }, 10);
    // Create Function To Loop For Each span to set new Dynamic Animation Number
    function animaitNumFunction(element) {
        element.forEach(eachElement => {
            eachElement.innerHTML = count++;

            // stop animation
            if (Number(eachElement.innerHTML) === randomNums[Math.round(Math.random() * randomNums.length)]) {
                clearInterval(interval);
            }
        });
    };

    return setTimeout(() => clearInterval(interval), 5000);
};

export const toggleActiveClass = (elements, current_class) => {
    elements.forEach(el => {
        // add active for current target el
        el.addEventListener("click", (e) => {
            // Remove From All Spans
            elements.forEach(element => element.classList.remove(current_class));
            // Add For current Span
            e.target.classList.add(current_class);
        });
    });
}
export const HomeSectionLogic = () => {
    const SELECTlEVEL = document.querySelector(".selectLevel");
    const INPTITLE = document.querySelector(".inputTitle");
    const INPUTDESCRPTION = document.querySelector(".inputDesc");
    const TASKSWRAPPERELEMENT = document.querySelector(".tasks");
    const ADDNEWTASK = document.querySelector(".addNewTask");
    const CLOSENEWTASKBUTTON = document.querySelectorAll(".closeNewTaskButton");
    let arrayOftasks = [
        {
            id: randomNum(),
            deleteId: randomNum(),
            type: "card",
            title: "Design Meeting",
            level: "High",
            descrption: "Development Task Assign For the Product Page Project, collaboration with the desgin"
        },
        {
            id: randomNum(),
            deleteId: randomNum(),
            type: "card",
            title: "Design Meeting",
            level: "Medium",
            descrption: "Development Task Assign For the Product Page Project, collaboration with the desgin",
        },
        {
            id: randomNum(),
            deleteId: randomNum(),
            type: "card",
            title: "Design Meeting",
            level: "Low",
            descrption: "Development Task Assign For the Product Page Project, collaboration with the desgin",
        },
        {
            id: randomNum(),
            deleteId: randomNum(),
            type: "plus",
            title: "Design Meeting",
            level: "Medium",
            descrption: "Development Task Assign For the Product Page Project, collaboration with the desgin",
        },
    ];
    arrayOftasks = getLocalStorageData("cards") || arrayOftasks;
    // create Function to Get Value From User
    let tasksObject = Object({});
    // Add Data to LocalStorage
    localStorage.setItem("cards", convertToJson(arrayOftasks));

    const getValueFromUser = () => {

        tasksObject = Object({
            id: Math.random(),
            deleteId: Math.random(),
            level: null,
            // this Function To Set The Current Level User Choseen
            setLevel: function () {
                SELECTlEVEL.addEventListener("click", () => {
                    tasksObject.level = SELECTlEVEL.value;
                });
            }
        });


        // Set object value when user write it from Form Componant
        tasksObject.title = INPTITLE.value;
        tasksObject.descrption = INPUTDESCRPTION.value;
        tasksObject.level = SELECTlEVEL.value;
        tasksObject.type = "card";

    }
    // ==================================  Active Plust Button ====================================
    // this Function to Re_active New task btn
    const ACTIVE_PLUS_BUTTON = () => {
        const ADDPLUSBUTTON = document.querySelector(".plus-btn");
        ADDPLUSBUTTON.addEventListener("click", () => {
            ADDNEWTASK.classList.add("active_scale_with_right");
            // Loob for every input and make it empty
            document.querySelectorAll("input").forEach(input => input.value = "");
        });
        // Hide Form
        CLOSENEWTASKBUTTON.forEach(btn => btn.addEventListener("click", () => {
            ADDNEWTASK.classList.remove("active_scale_with_right");
        }));
    }
    // Create Function To Delete Task
    const deleteTask = () => {
        let CARDS = document.querySelectorAll(".card");
        CARDS.forEach(card => {
            card.addEventListener("click", event => {

                // Check If target click contains target class to compaer it with each item in  array of tasks

                if (event.target.classList.contains("dl-target")) {
                    arrayOftasks = arrayOftasks.filter(item => item.deleteId != event.target.getAttribute("data-del"));
                    darwCardsUI(arrayOftasks);
                    toggleDeleteBtn();
                }
            })
        });
    };
    const darwCardsUI = (cards) => {


        TASKSWRAPPERELEMENT.innerHTML = "";

        let cardsUI = cards.map(card => {
            // Check For Card Type To Set The Corract Ui
            if (card.type === "card")
                return homeCard(card);
            else
                return plusCard();
        });

        // append all cards to parent div
        let elements = [...cardsUI];
        for (let i = 0; i < elements.length; i++) {
            TASKSWRAPPERELEMENT.innerHTML += elements[i];
        }

        // Calling Function ACTIVE_PLUS_BUTTON to active plus btn when any change happen
        ACTIVE_PLUS_BUTTON();

        // Calling Function deleteTask to active delete btn when any change happen
        deleteTask();

        localStorage.setItem("cards", convertToJson(arrayOftasks));
    }
    // calling Darw Cards Function to darw ui cards
    darwCardsUI(arrayOftasks);
    const toggleDeleteBtn = () => {
        const DELETE_BUTTON_ICONE = document.querySelectorAll(".fa-ellipsis-vertical");
        const BUTTONS = document.querySelectorAll(".dl-btn");

        DELETE_BUTTON_ICONE.forEach(cureent_icone => {
            cureent_icone.parentElement.addEventListener("click", () => {
                BUTTONS.forEach(cureent_btn => {
                    if (cureent_btn.getAttribute("data-id") == cureent_icone.getAttribute("data-id")) {
                        cureent_btn.classList.toggle("active");
                    }
                });
            });
        });
    };
    toggleDeleteBtn();

    // calling delete Task Function 
    deleteTask();
    // =============================  Draggeble Elements Logic   =================================

    function draggableElements() {
        const CARDS = document.querySelectorAll(".card");
        const container = document.querySelector(".tasks");
        CARDS.forEach(card => {
            card.addEventListener("dragstart", () => card.classList.add("dragging"));
            card.addEventListener("dragend", () => card.classList.remove("dragging"));
        });

        container.addEventListener("dragover", (e) => {
            e.preventDefault();
            const AFTER_ELEMENT = getDragAfterElement(container, e.clientY);
            const DRAGGEBLE = document.querySelector(".dragging");
            if (AFTER_ELEMENT === null) {
                container.appendChild(DRAGGEBLE);
            } else {
                container.insertBefore(DRAGGEBLE, AFTER_ELEMENT);
            }
        });

        // this Function to set element in the right place
        function getDragAfterElement(container, y) {
            const DRAGGEBLE_ELEMENTS = [...container.querySelectorAll(".draggable:not(.dragging)")];
            return DRAGGEBLE_ELEMENTS.reduce((closest, child) => {
                const box = child.getBoundingClientRect();
                const offset = y - box.top - box.height / 2;

                if (offset < 0 && offset > closest.offset) {
                    return { offset: offset, element: child }
                } else {
                    return closest;
                }

            }, { offset: Number.NEGATIVE_INFINITY }).element;
        }
    }
    // Add Task To Array Fucntion
    const addTaskToArray = () => {

        getValueFromUser();

        arrayOftasks.push(tasksObject);

        darwCardsUI(arrayOftasks);

        deleteTask();
        toggleDeleteBtn();

    };

    // =========== This  Function To Active Btn new task when user click on it  ===========
    const ACTIVE_BUTTON_NEW_TASK = () => {
        const ADD_NEW_TASK = document.querySelector(".addNewTask");
        const ADD_NEW_TASK_BUTTON = document.querySelector(".addNewTaskButton");

        ACTIVE_PLUS_BUTTON();

        ADD_NEW_TASK_BUTTON.addEventListener("click", () => {
            addTaskToArray();
            ADD_NEW_TASK.classList.remove("active_scale_with_right");
        });

    }
    ACTIVE_BUTTON_NEW_TASK();

    draggableElements();
}

// table funtionality
export const tableLogic = () => {
    // ==================== Create Function To Filter table Role ==================== 
    const FILTER_ROLE = () => {
        let SELECT_BOX = document.querySelector("#filter_Select");
        // ====================  Set toggle Classes When User Select option From Select Box ===================
        SELECT_BOX.addEventListener("click", () => {
            let VALUE = SELECT_BOX.value;

            // ==================== Set Value To LocalStorage ==================== 
            localStorage.setItem("table Select", VALUE);

            // ==================== Calling Function When User Select To Show only user select ===================
            FILTER_TABLE_BY_USER_TYPE(VALUE);
        });

    }

    // ==================== Create Function To Set Condation to Controal Elements ====================
    const FILTER_TABLE_BY_USER_TYPE = (value) => {
        let ADMINS = document.querySelectorAll(".admin");
        let PARTENR = document.querySelectorAll(".partner");
        const TABLE_ROW_CLASS = "table-row";
        const TABLE_NONE_CLASS = "none";

        if (value == "admin")
            TOGGLE_TABLE_ROW_CLASSES({ partner: PARTENR, admins: ADMINS, classOne: TABLE_ROW_CLASS, classTwo: TABLE_NONE_CLASS });
        if (value == "partner")
            TOGGLE_TABLE_ROW_CLASSES({ partner: PARTENR, admins: ADMINS, classOne: TABLE_NONE_CLASS, classTwo: TABLE_ROW_CLASS });
        if (value == "All")
            TOGGLE_TABLE_ROW_CLASSES({ partner: PARTENR, admins: ADMINS, classOne: TABLE_ROW_CLASS, classTwo: TABLE_ROW_CLASS });
    }

    FILTER_ROLE();

    // ==================== Create Function To Hide And Show table elements ==================== 

    const TOGGLE_TABLE_ROW_CLASSES = (elments) => {
        elments.admins.forEach(admin => admin.style.display = elments.classOne);
        elments.partner.forEach(admin => admin.style.display = elments.classTwo);
    }

    /**
     * Sets the selected option of the user filter select element based on the value stored in localStorage.
     */
    let selectedFilter = document.querySelector("#filter_Select");
    const SET_USER_SELECT_OPTION = () => {
        // Get the value of the user filter select element
        let selectedFilterValue = selectedFilter.value;

        // Check if localStorage has an item set for the user filter select element
        if (localStorage.getItem("table Select")) {
            // Get the value of the user filter select element from localStorage
            selectedFilterValue = localStorage.getItem("table Select");

            // Set the "selected" attribute of the option element with the matching value, and remove it from all other options
            let optionsElements = document.querySelectorAll("#filter_Select option");
            optionsElements.forEach(option => option.value == selectedFilterValue ? option.setAttribute("selected", "") : option.removeAttribute("selected"));
        }
    }


    SET_USER_SELECT_OPTION();

    FILTER_TABLE_BY_USER_TYPE(selectedFilter);


    //  ==================== this Function To Sort table  ==================== 
    const tableTr = document.querySelectorAll("table tbody tr");

    /**
     * Sorts a table by a specified column and order.
    */
    const sortTableByColumn = (table, column, asc = true, sortBy = "admin") => {
        // Determine the sorting direction modifier based on the "asc" parameter
        const dirModifier = asc ? 1 : -1;

        // Get a reference to the table body and an array of its rows
        const tBody = table.tBodies[0];
        const rows = Array.from(tBody.querySelectorAll("tr"));

        // Sort each row based on the specified column and sorting order
        const sortedRows = rows.sort((a, b) => {
            const aColText = a.querySelector(`td:nth-child(${column + 2})`).textContent.trim();
            const bColText = b.querySelector(`td:nth-child(${column + 2})`).textContent.trim();

            // Determine the sorting order based on the "sortBy" parameter
            if (sortBy == "partner") {
                return aColText > bColText ? (-1 * dirModifier) : (1 * dirModifier);
            } else {
                return aColText > bColText ? (1 * dirModifier) : (-1 * dirModifier);
            }
        });

        // Remove all existing rows from the table body and add the sorted rows back in
        while (tBody.firstChild) {
            tBody.removeChild(tBody.firstChild);
        }
        tBody.append(...sortedRows);
    }

    // This Function To Set Default Sort For Table

    const defaultSort = _ => {
        const tableTbody = document.querySelectorAll("table tbody tr");
        tableTbody.forEach(el => el.remove());
        const tbody = document.querySelector("table tbody");
        tableTr.forEach(tr => {
            tbody.append(tr);
        });
    }

    /**
     * Handles the sorting of a table based on the selected option in a sort select element.

     */
    const SORT_TABLE_BY_SELECTED_OPTION = _ => {
        // Get a reference to the sort select element
        let sortSelect = document.querySelector("#sort");

        // Add a change event listener to the sort select element
        sortSelect.addEventListener("change", () => {
            // Determine which sort option was selected and sort the table accordingly
            if (sortSelect.value == "admin-partner") {
                // Sort the table by the "admin" column in ascending order
                sortTableByColumn(document.querySelector("table"), 1, true, "admin");
            } else if (sortSelect.value == "partner-admin") {
                // Sort the table by the "partner" column in ascending order
                sortTableByColumn(document.querySelector("table"), 1, true, "partner");
            } else if (sortSelect.value == "default") {
                // Sort the table using the default sorting function
                defaultSort();
            }
        });
    }
    SORT_TABLE_BY_SELECTED_OPTION();

}

// Calendar
export const setDateUi = _ => {
    const arrayOfMonths = ["Jan", "Feb", "March", "April", "May", "June", "July", "August", "Sep", "Oct", "Nov", "Dec"];
    // date variables

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let last_day = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    let last_day_index = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDay();
    let first_day_Index = date.getDay();
    let prev_last_day = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
    let next_days = 7 - last_day_index - 1;

    // select elements
    const MONTH_ELEMENT = document.querySelector(".month");
    const YEAR_ELEMENT = document.querySelector(".year");
    const days = document.querySelector(".days");
    const left_angle = document.querySelector(".left-angel");
    const right_angle = document.querySelector(".right-angel");
    MONTH_ELEMENT.innerHTML = arrayOfMonths[month];
    YEAR_ELEMENT.innerHTML = year;

    // set new year when user click on angles

    // this function check for what is opration user wanna do it
    const setMonthYearOnClick = (element, operation) => {
        // Get the current month and year values
        let month = new Date().getMonth();
        let year = new Date().getFullYear();

        // Add a click event listener to the specified element
        element.addEventListener("click", () => {
            // Increment or decrement the month and year values based on the specified operation
            if (operation === "+") {
                month += 1;
                if (month === 12) {
                    month = 0;
                    year += 1;
                }
                // Update the month and year elements with the new values
                MONTH_ELEMENT.innerHTML = arrayOfMonths[month];
                YEAR_ELEMENT.innerHTML = year;
            } else {
                if (month === 0) {
                    month = 12;
                    year -= 1;
                }
                month -= 1;
                // Update the month and year elements with the new values
                MONTH_ELEMENT.innerHTML = arrayOfMonths[month];
                YEAR_ELEMENT.innerHTML = year;
            }
        });
    };

    setMonthYearOnClick(right_angle, "+");
    setMonthYearOnClick(left_angle, "-");
    /**
     * Renders the days of the calendar UI based on the current month and year.
     */
    const renderCalendarDays = () => {
        // Clear the current days in the UI
        days.innerHTML = "";

        // Render the days from the previous month that are still visible in the current month's calendar view
        for (let i = first_day_Index; i > 0; i--) {
            days.innerHTML += `<p style=" width: calc(100% / 7);" class="prevLastDays fw-bold p-2 mb-2"><span style="cursor: no-drop;">${prev_last_day - i + 1}</span></p>`;
        }

        // Render the days of the current month
        for (let i = 1; i <= last_day; i++) {
            days.innerHTML += `<p style=" width: calc(100% / 7);" class="fw-bold p-2 mb-2"><span style="cursor:pointer;" class="month-day" data-day="${i}">${i}</span></p>`;
        }

        // Render the days from the next month that are visible in the current month's calendar view
        for (let j = 1; j <= next_days; j++) {
            days.innerHTML += `<p style=" width: calc(100% / 7);" class="nextDays fw-bold p-2 mb-2"><span style="cursor: no-drop;">${j}</span></p>`;
        }
    };
    renderCalendarDays();

    // ADD active class to current element
    toggleActiveClass(days.querySelectorAll("p span.month-day"), "current_day");

    // Set active current_day calss to current day
    const currentDayClass = () => {
        return [...days.children].forEach(element => (element.children[0].getAttribute("data-day") == day) ? element.children[0].classList.add("current_day") : element);
    }
    currentDayClass();
};

// ChatBoot 
export const chatLogic = () => {
    const chatWrapper = document.querySelector(".chat-wrapper");
    const chatInput = document.querySelector(".user-chat input");
    let autoMessageTimeout;
    // Create a function to draw the UI for a message
    const renderMessageBoot = (message, time, sender) => {
        const messageContainer = document.createElement("div");
        // classList.add it's can acsspet more than one class
        messageContainer.classList.add("d-flex-center", "p-1", "mb-2");
        messageContainer.style.cssText = "box-shadow: 0px 0px 2110px 20px #575f6e38 inset; border-radius: 5px; width: fit-content; max-width: 90%;";

        const messageText = document.createElement("p");
        messageText.style.cssText = "font-weight: 400; font-size: 12px; width: 85%; word-break: break-all;";
        messageText.innerHTML = message;

        const messageTime = document.createElement("span");
        messageTime.style.cssText = "width: 10%; font-size: 11px; align-self: flex-end; margin: 0 15px !important;";
        messageTime.classList.add("text-white-50", "fw-bold");
        messageTime.innerHTML = time;

        if (sender === "user") {
            messageContainer.style.alignSelf = "flex-end";
            messageContainer.style.boxShadow = "0px 0px 2212210px 20px #575f6e38 inset";
        }
        // loop for messages to append it to container
        [messageText, messageTime].forEach(message => messageContainer.appendChild(message));
        chatWrapper.appendChild(messageContainer);
    };
    // Create a function to send a message from the bot
    const sendAutoMessage = () => {
        const messageIndex = Math.floor(Math.random() * messages.length);
        const message = messages[messageIndex];
        const date = new Date();
        const time = `${date.getHours()}:${date.getMinutes()}`;

        renderMessageBoot(message, time, "boot");
    };
    // Create an event listener for the chat input
    chatInput.addEventListener("keyup", (event) => {
        if (event.key === "Enter") {
            const message = event.target.value;
            const date = new Date();
            const time = `${date.getHours()}:${date.getMinutes()}`;

            if (message != "") {
                renderMessageBoot(message, time, "user");
                event.target.value = "";

                // Clear the previous timeout and set a new one
                clearTimeout(autoMessageTimeout);
                autoMessageTimeout = setTimeout(() => {
                    sendAutoMessage();
                }, 2000);
            }
        }
    });
};

// Setting Page
export const deleteUsers = () => {
    const DELETE_MESSAGE_ELEMENT = document.querySelector(".delete-member");
    const CLOSE_MESSAGE_BUTTON = document.querySelector(".member-btn");
    const KEEP_MEMBER_BUTTON = document.querySelector(".delete-member .main-btn ");
    const REMOVE_MEMBER_BUTTON = document.querySelectorAll(".delete-member .main-btn")[1];
    const PARAGRAPH_MESSAGE = document.querySelector(".delete-member p ");
    const TRASHS_ICONE = document.querySelectorAll(".trash");
    // Check if target click have the same class of delete icone to set active class to delete message
    TRASHS_ICONE.forEach(trash => trash.addEventListener("click", () => DELETE_MESSAGE_ELEMENT.classList.add("scale_controal")));
    // ================ remvoe active class when user click on cansel message ================
    CLOSE_MESSAGE_BUTTON.addEventListener("click", () => DELETE_MESSAGE_ELEMENT.classList.remove("scale_controal"));

    // ================ remvoe active class when user click on don't delete Member ================
    KEEP_MEMBER_BUTTON.addEventListener("click", () => DELETE_MESSAGE_ELEMENT.classList.remove("scale_controal"));


    // ================ show role_message when user click on REMOVE_MEMBER_BUTTON ================
    REMOVE_MEMBER_BUTTON.addEventListener("click", () => {
        PARAGRAPH_MESSAGE.innerHTML = "Hello Sir, Sorry You don't have Role to Delete This employee";
        let textTimeOut;
        clearTimeout(textTimeOut);
        textTimeOut = setTimeout(() => {
            PARAGRAPH_MESSAGE.innerHTML = " You are about to remove a member witch will not allow this Person to login into your organization anymore";
        }, 3000);
    })

}

export const handelInvaiteLogic = (images) => {
    // ============================== Create array of data to set data from it ======================

    let arrayOfItems = [
        {
            id: Math.random(),
            fName: "Esther",
            lastName: "Howard",
            email: "esther.howard@gmail.com",
            role: "admin",
        },
        {
            id: Math.random(),
            fName: "Kristin",
            lastName: "Waston",
            email: "kristin.waston@gmail.com",
            role: "reviewer",
        }
    ];
    arrayOfItems = getLocalStorageData("users") || arrayOfItems;
    let object = {}

    const darwInviteUI = (arrayOfItems, deleteFromUi, images) => {
        let wrapper_Elements = document.querySelector(".peopel-invites");

        wrapper_Elements.innerHTML = "";

        let renderPerson = arrayOfItems.map((person) => inveitePersonUi(person, images));

        // ============================== append each element to parent div ==============================

        const PEOPLE = [...renderPerson];
        for (let i = 0; i < PEOPLE.length; i++) {
            wrapper_Elements.innerHTML += PEOPLE[i];
        }

        deleteFromUi();

    }

    darwInviteUI(arrayOfItems, deleteFromUi, images, randomNum);
    // ============================== Create Function To Get Value From User ==============================

    const getValueFromUser = () => {
        const INPUT_FIRST_NAME = document.querySelector("#first_Name");
        const INPUT_LAST_NAME = document.querySelector("#last_Name");
        const INPUT_EMAIL = document.querySelector("#email_add");

        // ============================== Set Object Values ==============================
        object = {
            id: Math.random(),
            fName: INPUT_FIRST_NAME.value,
            lastName: INPUT_LAST_NAME.value,
            email: INPUT_EMAIL.value,
        }

    }
    // Select the HTML element with class "invite-btn"
    const INVITE_BUTTON = document.querySelector(".invite-btn");

    // Select the HTML element with class "invite-form"
    const FORM = document.querySelector(".invite-form");

    // Add a click event listener to INVITE_BUTTON
    INVITE_BUTTON.addEventListener("click", () => {
        // When INVITE_BUTTON is clicked, add the class "scale_controal" to FORM
        FORM.classList.add("scale_controal");
    });

    const addNewTask = () => {
        getValueFromUser();

        arrayOfItems.push(object);

        // ============================== push new user to localstorage ==============================
        addUsersToLocalStorage(arrayOfItems);
    }
    function hideFromUi(current_btn, element_to_hide) {
        // hide form if user click on casnel btn
        current_btn.addEventListener("click", () => {
            element_to_hide.classList.remove("scale_controal");
        });
    };

    // ============================== Create function to add person When user click on add btn ==============================

    const activeAddPerson = () => {
        const INVITE_FORM = document.querySelector(".invite-form");
        const INVITE_BUTTON = document.querySelector(".invite");
        const CANSCEL_BUTTON = document.querySelector(".cansel");

        // ======================= add person if user click on invite btn ========================
        INVITE_BUTTON.addEventListener("click", () => {
            // ====================== Calling function DarwUi to set a element to page ==============================
            addNewTask();
            darwInviteUI(arrayOfItems, deleteFromUi, images, randomNum);
            document.querySelectorAll(".invite-form input").forEach(input => input.value = "");
            // Rmove active Class from invite-form when user add a new person
            INVITE_FORM.classList.remove("scale_controal");
        });

        // ============================== hide form if user click on casnel btn or cansel icone ==============================
        // We import this funtion From Basic Function Js File
        hideFromUi(CANSCEL_BUTTON, INVITE_FORM);
        hideFromUi(document.querySelector(".cansel-intvite-btn"), INVITE_FORM);
    }
    activeAddPerson();

    // ============================== this Function add users to local Storage ==============================

    function addUsersToLocalStorage(arrayOfItems) {
        let data = JSON.stringify(arrayOfItems);

        // add it to localStorage
        localStorage.setItem("users", data);

    };

    addUsersToLocalStorage(arrayOfItems);

    // Create another Function To Delete user from localStorage

    const deleteFromLocalStorage = (userId) => {
        let data = JSON.parse(localStorage.getItem("users"));
        if (data) {
            // ============================== filtering array to set the correct users ==============================
            arrayOfItems = arrayOfItems.filter(user => user.id != userId.id);
            // ============================== add new users to localstorage ==============================
            localStorage.setItem("users", JSON.stringify(arrayOfItems));
            darwInviteUI(arrayOfItems, deleteFromUi, images, randomNum);
        }
    }

    // ============================== create Function To Delete user from page ui ==============================

    function deleteFromUi() {
        const REMOVE_BUTTON = document.querySelectorAll(".delete-invite");

        REMOVE_BUTTON.forEach(btn => {
            btn.addEventListener("click", () => {
                // ============================== filtering array to set the correct users ==============================
                arrayOfItems = arrayOfItems.filter(user => user.id != btn.id);

                // ====================== Calling Function delete from local to update local storage =========================
                deleteFromLocalStorage(btn.id);

                // ==================== calling ui function to update ui ==============================
                darwInviteUI(arrayOfItems, deleteFromUi, images, randomNum);
            });
        });
    }
    const resendInvite = () => {
        const resend_Btns = document.querySelectorAll(".resend_invite");
        let message = document.createElement("div");
        let removeDiv;
        message.classList.add("resend-message")
        message.innerHTML = "Hey, We're Resend Message From This User Again";
        message.style.cssText = "padding: 20px !important; background-color: #8c2f4a; border: 2px solid #1a1c20; color:white; font-weight:bold; font-size:14px;";
        resend_Btns.forEach(btn => {
            btn.addEventListener("click", () => {
                document.body.appendChild(message);
                // ==================== this variable to remove element after 4 sec =====================
                removeDiv = setTimeout(() => message.remove(), 6000);
            });
        });

        return clearTimeout(removeDiv);
    }
    resendInvite();
    return;
}

// Define a function to get the user's name
export const GET_USER_NAME = () => {
    // Select the first name and last name input fields
    const INPUT_FIRST_NAME = document.querySelector(".sign-box input");
    const INPUT_LAST_NAME = document.querySelectorAll(".sign-box input")[1];

    // Select the submit button
    const form_btn = document.querySelector(".btn-form");

    // Initialize a variable to store the user's name
    let name = "";

    // Add a blur event listener to the first name input field to update the name variable
    INPUT_FIRST_NAME.addEventListener("blur", (e) => name += e.target.value);

    // Add a blur event listener to the last name input field to update the name variable
    INPUT_LAST_NAME.addEventListener("blur", (e) => name += " " + e.target.value);

    // Add a click event listener to the submit button to save the user's name to local storage and clear the input fields
    form_btn.addEventListener("click", () => {
        localStorage.setItem("name", name);
        [INPUT_FIRST_NAME, INPUT_LAST_NAME].forEach(input => input.value = "");
    });
}