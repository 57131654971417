import { useEffect } from "react";
import DeleteMessage from "./DeleteMessage/DeleteMessage";
import PendingInvites from "./PendingInvites/PendingInvites";
import { deleteUsers, handelInvaiteLogic } from "../../../utilities/utilities";
import InvitePeople from "./InvitePeople/InvitePeople";
import { images, users } from "../../../utilities/data";
import UserEntry from "../UserEntry";

const SettingUsers = () => {
    
    const darwUsersUi = users.map((user, index) => <UserEntry key={index} user={user} />);
    useEffect(() => {
        deleteUsers();
        handelInvaiteLogic(images);
    })
    return (
        <div className="users-control row position-relative mt-2 pb-3">
            <div className="col-lg-4 p-4 mb-3">
                <div className="mb-3">
                    <h3 className="fw-bold mb-4 text-white">Users</h3>
                    <p style={{ lineHeight: '1.8', fontWeight: '600', fontSize: '15px' }} className="text-white-50 fw-bold">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, illum corrupti.
                        Doloremque, est aliquam quis consequatur
                        tempore voluptatem nulla nihil maxime odio ipsam officia in dolore? Id tempora
                        ducimus assumenda!
                    </p>
                </div>
                <button className="main-btn invite-btn" style={{ backgroundColor: "#0075ff" }}>Invite
                    People</button>
            </div>
            <div className="col-lg-8 p-0 mb-3" style={{ border: "2px solid var(--main-color)" }}>
                {darwUsersUi}
                <DeleteMessage />
            </div>
            <PendingInvites />
            <InvitePeople />
        </div>
    )
}

export default SettingUsers;