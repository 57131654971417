const DirectoryCard = ({dir , index}) => {
    return (
        <div key={index} className="d-flex-between d-flex mb-3 align-items-center"
            style={{ backgroundColor: "var(--main-color)", borderRadius: "3px" }}>
            <div className="text-center d-flex align-items-center me-3  pe-2 ps-2"
                style={{ width: "fit-content", cursor: "pointer", borderRadius: "5px" }}>
                <i className="fa-solid fa-folder mb-2 me-3"
                    style={{ fontSize: "30px", color: "var(--near-to-yellow)" }}></i>
                <p className="fw-bold">{dir.name}</p>
            </div>
            <div style={{ width: "40%" }} className="p-2 d-flex-between d-flex size">
                <p>{dir.day}</p>
                <p>{dir.size}</p>
                <i className="fa-solid fa-ellipsis pe-3" style={{ cursor: "pointer" }}></i>
            </div>
        </div>
    )
}

export default DirectoryCard;