export const homeCard = card => {
    return (
        `<div data-del="${card.deleteId}" draggable="true" class="draggable p-3 card  mb-3 position-relative"
            style="width: calc(97% / 2); border-radius: 5px; background-color: var(--second-color); ">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center mb-3">
                <h2 class="fw-bold me-2 text-white" style="font-size: 20px;">${card.title}
                    </h2>
                <div class="lvl ${card.level.toLowerCase()} fw-bold">${card.level}</div>
        </div>
            <span>
                <i data-id="${card.id}" class="fa-solid fa-ellipsis-vertical text-white-50"
                    style="cursor: pointer;"></i>
            </span>
            <div data-id="${card.id}" class="dl-btn text-white-50 position-absolute"
                style="transition: var(--trasnition); cursor: pointer; border-radius: 4px; background-color: #6a6a6a; padding: 5px 20px !important; right: 25px; top: 40px ; opacity: 0; transform: scale(0); visibility: hidden;">
                <p class="dl-target" data-del="${card.deleteId}">Delete</p>
            </div>
    </div>
        <p class="text-white-50 mb-3 fw-bold" style="font-size: 14px; max-width: 80%;"> ${card.descrption}</p>
            <div>
            <div class="d-flex" style="max-width: 100%;">
            <img src="${require("../images/Elipse15.png")}" alt="img"
                style="max-width: 30px;">
            <img src="${require("../images/Elipse15.png")}" alt="img"
                style="max-width: 30px;margin-left: -5px !important;">
            <img src="${require("../images/Elipse15.png")}" alt="img"
                style="max-width: 30px;margin-left: -5px !important;">
            <img src="${require("../images/Elipse15.png")}" alt="img"
                style="max-width: 30px;margin-left: -5px !important;">
            <img src="${require("../images/Elipse15.png")}" alt="img"
                style="max-width: 30px;margin-left: -5px !important;">
            </div>
            </div>
            </div>`
    )
}

export const plusCard = _ => {
    return (
        ` <div draggable="true"
        class="card draggable p-2  d-flex justify-content-center align-items-center mb-3"
        style="width: calc(97% / 2);  min-height: 162px; border-radius: 5px; background-color: var(--second-color);">
        <div class="text-center">
        <div class="mb-2 d-flex justify-content-center plus-btn align-items-center"
            style="width: 35px; height: 35px; background-color: #6a6a6a; cursor: pointer; border-radius: 50%; margin: 16px auto !important;">
            <span class="text-white fw-bold">x</span>
        </div>
        <h3 class="text-white-50 fw-bold" style="font-size: 15px;">Add New Task</h3>
        </div>
    </div> `
    );
}

export const inveitePersonUi = (person, images) => {
    return (
        `<div class="person d-flex d-flex-between p-2 ${person.role}" id="${person.id}"  style="background-color: var(--off-dark); border-bottom: 1px solid #444;">
    <div class="d-flex align-items-center">
        <img src="${images[Math.floor(Math.random() * images.length)]}"
            style="margin-right: 10px !important; width: 30px; height:30px; border-radius: 50%;" alt="img">
        <div class="person-info">
            <h3 class="fw-bold  mb-2" style="font-size: 13px;">${person.fName} ${person.lastName} <span
    style="padding: 5px !important; color:white; border-radius: 4px; margin-left: 5px !important; background-color: #404148;">Panding</span>
            </h3>
            <p class="text-white-50 fw-bold" style="font-size: 12px; margin-bottom: 10px !important;">${person.email}</p>
        </div>
    </div>
    <div class="d-flex align-items-center person-btns">
        <div class="main-btn resend_invite me-3" style="background-color: #0075ff ; font-size: 13px;">Resend Invite
        </div>
        <div class="main-btn delete-invite" id="${person.id}" style="background-color: #ca5374; color: white; font-size: 13px;">
            Remove
            Invite</div>
    </div>
</div>`
    );
}