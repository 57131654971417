import ProjectMetricsDashboard from "./ProjectMetricsDashboard";

const OverallProgress = () => {
    return (
        <div
            className="p-3 mb-3 overflow-hidden" id='overallProgress'
            style={{ backgroundColor: 'var(--main-color)', borderRadius: '10px', marginBottom: '20px !important' }}>
            <div className="d-flex d-flex-between">
                <h1 className="text-white fw-bold" style={{ fontSize: "20px" }}>Overall Progress</h1>
                <p><a href="#" style={{ color: "#5d513a", fontWeight: "bold", fontSize: "13px" }}>See All</a></p>
            </div>
            <div className="d-flex align-items-center mt-3 mb-3">
                <span className="text-white"><i className="fa-solid fa-calendar-days me-3"></i></span>
                <p className="text-white fw-bold" style={{ fontSize: "11px" }}>Last 7 days</p>
                <span className="text-white"><i className="fa-solid fa-angle-down ms-3"></i></span>
            </div>
            <div>
                <ProjectMetricsDashboard />
            </div>
        </div>
    )
}

export default OverallProgress;