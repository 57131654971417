const PersonalInfoForm = () => {
    return (
        <div id="general_info" className="p-3 mb-3 setting-media sign-box"
            style={{ minHeight: 372, borderRadius: 10, backgroundColor: 'var(--main-color)', width: 'calc(97% / 3)' }}>
            <h3 className="fw-bolder mb-2">General Info</h3>
            <p style={{ fontSize: 11 }} className="fw-bold text-white-50">General Info About Your Account</p>
            <div className="d-flex flex-column mb-4 mt-3">
                <label className="text-white-50 mb-1 fw-bold" style={{ fontSize: 11 }}>First Name</label>
                <input
                    style={{ padding: '8px', backgroundColor: 'var(--second-color)', border: 'none', outline: 'none', fontWeight: 600, color: 'white' }}
                    type="text" placeholder="First Name" />
            </div>
            <div className="d-flex flex-column mb-4">
                <label className="text-white-50 mb-1 fw-bold" style={{ fontSize: 11 }}>Last Name</label>
                <input
                    style={{ padding: '8px', backgroundColor: 'var(--second-color)', border: 'none', outline: 'none', fontWeight: 600, color: 'white' }}
                    type="text" placeholder="Last Name" />
            </div>
            <div className="d-flex flex-column mb-4">
                <label className="text-white-50 mb-1 fw-bold" style={{ fontSize: 11 }}>Email</label>
                <input
                    style={{ padding: '8px', backgroundColor: 'var(--second-color)', border: 'none', outline: 'none', fontWeight: 600, color: 'white' }}
                    type="email" disabled placeholder="nabowy.work@gmail.com" />
            </div>
            <div className="text-center">
                <button className="btn-form main-btn"
                    style={{ margin: '0 !important', backgroundColor: '#0075ff' }}>Submit</button>
            </div>
        </div>
    )
}
export default PersonalInfoForm;