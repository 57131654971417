import Header from "../Header/Header";
import { activeFolders, foldars } from "../utilities/data";
import DirectoryCard from "./DirectoryCard";
import FileCard from "./FileCard";

const Files = () => {
    const darwActiveFolders = activeFolders.map((dir, index) => <DirectoryCard dir={dir} index={index} />)
    const darwFolders = foldars.map((folder, index) => <FileCard folder={folder} index={index} />)
    return (
        <div className="page-sections  text-white"
            style={{ backgroundColor: "var(--bg-color)" }}>
            <Header />
            <div className="container">
                <h1 className="p-2 mt-5 fw-bold mb-3">Files</h1>
                <div className="folders d-flex-center flex-wrap p-2">
                    {darwFolders}
                </div>
                <div className="mb-3 p-2">
                    <h2 className="mb-4 mt-3 p-2 fw-bold">Active Files</h2>
                    {darwActiveFolders}
                </div>
            </div>
        </div>
    )
}
export default Files;