import { Fragment } from "react";

const TaskInputPanel = () => {
    return (
        <Fragment>
            <div className="p-4 w-100">
                <div className="w-100 mb-4">
                    <p className="text-white-50 fw-bold mb-1">Task Title</p>
                    <input type="text" className="p-3 inputTitle" style={{ color: "white", width: "100%", border: "1px solid", outline: "none", backgroundColor: "var(--bg-color)" }} />
                </div>
                <div className="w-100">
                    <div className="d-flex-between d-flex">
                        <p className="text-white-50 fw-bold mb-1">Custom Message</p>
                        <p style={{ fontSize: "14px" }} className="text-white-50">Optional</p>
                    </div>
                    <input type="text" className="p-3 inputDesc" style={{ color: "white", width: "100%", border: "1px solid", outline: "none", backgroundColor: "var(--bg-color)" }} />
                </div>
            </div>
            <div className="p-4 w-100 text-center">
                <h1 className="mb-4 fw-bold" style={{ fontSize: "16px" }}>Select Level</h1>
                <select className="fw-bold selectLevel w-25 text-center p-1 me-3 text-white-50" style={{ border: '1px solid #333', backgroundColor: "black", cursor: 'pointer' }}>
                    <option className="text-white" style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"High"}>High</option>
                    <option className="text-white" style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"Medium"}>Medium</option>
                    <option className="text-white" style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"Low"}>Low</option>
                </select>
                <div className="p-4 mb-2">
                </div>
            </div>
        </Fragment>
    )
}

export default TaskInputPanel;