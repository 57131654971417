import Header from "../Header/Header";

const Contact = () => {
    return (
        <div className="page-sections  text-white"
            style={{ backgroundColor: "var(--bg-color)" }}>
            <Header />
            <div className="container">
                <section className="mb-1">
                    <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: "calc(100vh - 200px)" }}>
                        <div className="row w-100">
                            <div className="col-lg-6">
                                <form onSubmit={e => e.preventDefault()} method="POST" className="d-flex flex-column mb-3">
                                    <input type="name" name="name" placeholder="username" className="mb-4 p-2"
                                        style={{ color: "#f6f6ff", fontWeight: "bold", fontSize: "13px", outline: "none", border: "none", backgroundColor: "#111214" }} />
                                    <input type="email" name="email" placeholder="Email" className="mb-4 p-2"
                                        style={{ color: "#f6f6ff", fontWeight: "bold", fontSize: "13px", outline: "none", border: "none", backgroundColor: "#111214" }} />
                                    <textarea rows="10" name="message" cols="" className="mb-4 p-2"
                                        style={{ color: "#f6f6ff", fontWeight: "bold", fontSize: "13px", outline: "none", border: "none", backgroundColor: "#111214" }}
                                        defaultValue="Your Message"></textarea>
                                    <input type="submit" style={{ backgroundColor: "#f4782e", color: "white", padding: "10px", fontWeight: "bold" }} />
                                </form>
                            </div>
                            <div className="col-lg-6">
                                <img src={require("./form.png")} alt="form-img" style={{ maxWidth: "100%" }} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Contact;