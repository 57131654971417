import Header from "../Header/Header";
import SettingContent from "./SettingContent";
const Setting = () => {
    return (
        <div className="page-sections bg-black" style={{ minHeight: "100vh" }}>
            <Header />
            <SettingContent />
        </div>
    )
}

export default Setting;