const ProjectHeader = ({buttonState}) => {
    const cardTitle = buttonState ? "Product Design & App Desgin" : "Startup Web with Resposive";
    return (
        <div className="d-flex align-items-center mt-3 mb-3">
            <div className="d-flex-center me-4" style={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor: 'var(--off-dark)' }}>
                <i className="fa-solid fa-rocket" style={{ fontSize: '18px', color: '#777' }}></i>
            </div>
            <div>
                <h3 className="fw-bold text-white mb-2" style={{ fontSize: '16px' }}>
                    {cardTitle}
                </h3>
                <p className="fw-bold text-white-50" style={{ fontSize: '13px' }}>
                    <i className="fa-solid fa-clock"></i>
                    <span>12:00 PM - 8:30 PM</span>
                </p>
            </div>
        </div>
    )
}

export default ProjectHeader;