export const images = [
    "https://img.freepik.com/free-photo/front-view-male-student-wearing-black-backpack-holding-copybooks-files-blue-wall_140725-42636.jpg?w=1380&t=st=1668347514~exp=1668348114~hmac=20ce02fd91a51e41cbff5bdfd3c6927ad6e6dd66fb36bd5e386f71575e16f03e",
    "https://img.freepik.com/free-photo/young-man-student-with-notebooks-showing-thumb-up-approval-smiling-satisfied-blue-studio-background_1258-65597.jpg?w=1380&t=st=1668347739~exp=1668348339~hmac=ef33d15202cf819ef8df01edfa1185b71cf031b4df74f094573f8055ccac84c1",
    "https://img.freepik.com/premium-photo/closeup-young-female-model-pointing-fingers-left-copy-space-showing-company-logo-smiling-standing-white-background_176420-52167.jpg?w=1380",
    "https://vtphna.org.au/wp-content/uploads/2019/09/Population-Health-Planning.jpg",
    "https://i.pinimg.com/564x/64/7a/f7/647af7807ffdaca2db134be75ca070e4.jpg",
    "https://i.pinimg.com/564x/f0/91/16/f09116b0c5f7520a04e92d8895ed5565.jpg",
    "https://i.pinimg.com/564x/26/83/51/26835149bd1a023178f9170f3c77da09.jpg",
    "https://i.pinimg.com/564x/37/6f/63/376f6346fc2dc3c1f1152f373ff97635.jpg",
    "https://i.pinimg.com/564x/fc/f3/78/fcf378615f9e010f6e440045fbc8e34f.jpg",
    "https://i.pinimg.com/564x/f2/64/3c/f2643ccaf96d592ec710511cb0ff58d4.jpg",
    "https://i.pinimg.com/564x/bb/ca/f3/bbcaf32e407c5e596ac1ed2aba8011b1.jpg",
    "https://i.pinimg.com/564x/2b/89/33/2b89337c52fe20e29793d3793f25ed27.jpg",
    "https://i.pinimg.com/564x/9a/4c/a5/9a4ca5d957a2d6aa7dd8b3c280eb83d0.jpg",
    "https://i.pinimg.com/736x/19/b6/fa/19b6fa6c5ebfadd4945c70afe89f169d.jpg",
    "https://i.pinimg.com/564x/e6/1a/25/e61a259cdbd196695f74e229579da342.jpg",
    "https://i.pinimg.com/564x/cc/36/3c/cc363c47b837914d2e8fdf71014a6f74.jpg",
    "https://i.pinimg.com/564x/31/69/05/3169056772a420bc366fd9b8b3fa1c27.jpg",
    "https://i.pinimg.com/564x/ce/63/47/ce63475562f960ede351c2533415adc5.jpg",
    "https://i.pinimg.com/564x/20/32/2e/20322e78cd112ee1f6bcc9dbb75c41ff.jpg",
    "https://i.pinimg.com/564x/af/d5/46/afd546fd2d3d793ab8e70826f7c9c04d.jpg",
    "https://i.pinimg.com/564x/02/ec/3e/02ec3ea583138986c6e34e3123996b80.jpg",
    "https://i.pinimg.com/564x/df/e4/9d/dfe49d1c0e3c83cb6622c396576ce4f4.jpg",
    "https://img.freepik.com/free-photo/portrait-man-with-beard-ukraine-sumy_8353-6061.jpg?size=626&ext=jpg&uid=R83129894&ga=GA1.2.935105513.1666868158",
    "https://img.freepik.com/free-photo/excited-little-boy-standing-showing-okay-gesture_171337-16369.jpg?w=1380&t=st=1668348157~exp=1668348757~hmac=7d98be5148fd8011b24bc6eab00316d873a8abff194a2b759809db5a32e5a981",
    "https://img.freepik.com/free-photo/excited-stylish-curly-haired-girl-sunglasses-pointing-right-showing-way_176420-20193.jpg?w=1380&amp;t=st=1668348020~exp=1668348620~hmac=60f633b3e633f17f0a9bc7e8fbed0dc46c2671306a60cc4d3c0060fdb6f70fb5",
    "https://img.freepik.com/free-photo/front-view-male-student-wearing-black-backpack-holding-copybooks-files-blue-wall_140725-42636.jpg?w=1380&t=st=1668347514~exp=1668348114~hmac=20ce02fd91a51e41cbff5bdfd3c6927ad6e6dd66fb36bd5e386f71575e16f03e"
];
export const dayTasks = [
    { time: "9:23", type: "Primary Task", subject: "Accounts and tax" },
    { time: "3:16", type: "Secondary Task", subject: "Business Plans" },
    { time: "7:36", type: "Additional Task", subject: "Risk Analysis" },
    { time: "10:52", type: "Daily Task", subject: "Preparing Budget forecasts" },
    { time: "2:17", type: "Desgin Meeting", subject: "Creating The Main UI Assests and illustaryion" },
    { time: "6:59", type: "Client Meeting", subject: "Updating The Current User interface" },
    { time: "8:47", type: "Adnin Meeting", subject: "Development Task Assign For The Product Page" },
];
export const person_Names = ["Tomas", "Michal", "Elena", "Jorge", "John", "Jesica", "Zula", "Seth", "Ted", "Keith", "Paul", "Ron", "Craig", "Bruce",];
export const foldars = ["Assest", 'Doucments', 'Oribble', 'Inpinations', 'Meckups', 'Projects', 'Videos', 'Images', 'Audios', 'Movies', 'Shorts',
    'Exams', 'Data', 'Texts', 'Funny Videos', 'Tamplates', 'courses', 'Effects', 'Funny Images', 'Elzero', 'Algorthims', 'D-Structure',
    'JavaScript', 'Tutorials', 'Problems', 'Data-base', 'Scripts', 'Functions', 'Programings', 'Games', 'Schedule', 'Tasks', 'Widnows',
    'OOP', 'PHP', 'Adv-Js',];
export const activeFolders = [
    { name: "Assest", day: "Today", size: "12.5GB" },
    { name: "Doucments", day: "17 Jun, 2022", size: "22.5GB" },
    { name: "Problems", day: "Yasterday", size: "13.5GB" },
    { name: "Tutorials", day: "Last Week", size: "46.5GB" },
    { name: "D-Structure", day: "Last Month", size: "8.5GB" },
    { name: "Scripts", day: "Today", size: "14.5GB" },
    { name: "Schedule", day: "Today", size: "52.5GB" },
    { name: "Movies", day: "24 July 2022", size: "11.5GB" },
    { name: "Algorthims", day: "16 Oct 2022", size: "98.5GB" },
    { name: "Assest", day: "Today", size: "12.5GB" },
];

export const tasksData = [
    {
        taskName: "Start up New Design",
        taskDesc: "Tamplate For New Client To Change Some info in his Project"
    },
    {
        taskName: "Work For Some Tamplates To Make Its Full Responsive",
        taskDesc: "ReChange For Some Tamplates at Small Media"
    },
    {
        taskName: "Desgin Meeting",
        taskDesc: "Creating The Main UI Assests and illustaryion For The Upcoming landing Pages Screens"
    },
    {
        taskName: "Client Meeting",
        taskDesc: "Updating The Current User interface of Header in The Picko Desinge Project"
    },
    {
        taskName: "Admin Meeting",
        taskDesc: "Development Task Assign For The Product Page Project collaboration With The Design"
    },
];

export const teamData = [
    { name: "Mahmoud i-Nabowy", role: "Frontend Developer" },
    { name: "will Loqso", role: "Project Manger" },
    { name: "Sareh Hosten", role: "Backend Developer" },
];

export const tableRowData = [
    { name: "Mahmoud I-Nabowy", user: "3 Partenrs (Full-Access)", role: "Admin", phone: "01125480514", data: "8/6/2000", status: "Active" },
    { name: "Marvin Mckinney", user: "28 Customers", role: "Partner", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
    { name: "Georgi Young", user: "54 Customers", role: "Partner", phone: "(480)555-0218", data: "8/10/20", status: "Pending" },
    { name: "Floyd Miles", user: "1 Customers", role: "Partner", phone: "(480)555-0218", data: "8/10/20", status: "Pending" },
    { name: "Tomas", user: "5 Customers", role: "Admin", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
    { name: "Elena", user: "2 Customers", role: "Partner", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
    { name: "Erick", user: "21 Customers", role: "Admin", phone: "(480)555-0218", data: "8/10/20", status: "Pending" },
    { name: "WIll Loqso", user: "7 Customers", role: "Partner", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
    { name: "Sareh Hostm", user: "12 Customers", role: "Admin", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
    { name: "Mahmoud I-Nabowy", user: "12 Customers", role: "Admin", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
    { name: "Marvin Mckinney", user: "3 Partners (Full-Access)", role: "Partner", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
    { name: "Georgi Young", user: "28 Customers", role: "Partner", phone: "(480)555-0218", data: "8/10/20", status: "Pending" },
    { name: "Floyd Miles", user: "54 Customers", role: "Partner", phone: "(480)555-0218", data: "8/10/20", status: "Pending" },
    { name: "Tomas", user: "1 Customers", role: "Admin", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
    { name: "Elena", user: "5 Customers", role: "Partner", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
    { name: "Erick", user: "2 Customers", role: "Admin", phone: "(480)555-0218", data: "8/10/20", status: "Pending" },
    { name: "WIll Loqso", user: "21 Customers", role: "Partner", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
    { name: "Sareh Hostm", user: "7 Customers", role: "Admin", phone: "(480)555-0218", data: "8/10/20", status: "Active" },
];

export const users = [
    {
        name: "Mahmoud i-Nabowy",
        email: "nabowy.work@gmail.com",
        role: "Administrator",
        imgUrl: images[Math.floor(Math.random() * images.length)]
    },
    {
        name: "Sareh Hosten",
        email: "Sareh.Hosten@gmail.com",
        role: "Project Manger",
        imgUrl: images[Math.floor(Math.random() * images.length)]
    },
    {
        name: "will Loqso",
        email: "will.Loqso@gmail.com",
        role: "Backend Developer",
        imgUrl: images[Math.floor(Math.random() * images.length)]
    },
    {
        name: "Elena Mckinney",
        email: "Elena.Mckinney@gmail.com",
        role: "Partner",
        imgUrl: images[Math.floor(Math.random() * images.length)]
    },
    {
        name: "Tomas Kiven",
        email: "Tomas.Kiven@gmail.com",
        role: "Frontend Developer",
        imgUrl: images[Math.floor(Math.random() * images.length)]
    },
    {
        name: "Marvin Mckinney",
        email: "Marvin.Mckinney@gmail.com",
        role: "Frontend Developer",
        imgUrl: images[Math.floor(Math.random() * images.length)]
    },
];
export const messages = [
    "Thanks for reaching out to Nabowy Dashboard! Our business hours are M-F, 9 AM-5 PM PT. We’ll get back to you within one business day.",
    "Thanks for contacting Jill at Nabowy Dashboard. I’m currently out of the office. I’ll be back at 1 PM and will respond to your message shortly.",
    "I’m currently camping in a WiFi-free zone! I’ll be back on May 10 to respond to your message. For urgent matters, please reach out to Joe at 01125480514.",
    "Our team is out spending time with loved ones. We’ll be back on November 29 to respond to your text. Have a great Thanksgiving weekend!",
    "We’ve got your back! A member of our support team will respond to your message within 2-3 hours. Thank you for your patience.",
    "Hello! We’re currently getting more texts than usual. We’ve received your message and will respond as soon as we can. Thank you for your patience.",
    "Thanks for your message! An expert will get back to you very soon. In the meantime, our help center may be able to answer your questions faster",
    "We’ve received your report. We’ll reach out if we need more information and let you know once the bug is resolved. Thanks for helping our site perform better!",
    "Sorry we missed your call! A member of our team will get back to you by the end of the day.",
    "Thanks for leaving a voicemail! We appreciate your patience and will get back to you by the end of the day.",
    "Thanks for contacting Nabowy Dashboard! One of our sales reps will reach out to you shortly. Tap to browse our summer sale while you wait: nabowy.online",
    "Thanks for contacting Nabowy Dashboard! We’ll reach out within an hour. Have you read our latest eBook? Fill out this form to get your free copy",
    "Thanks for reaching out! This inbox is not monitored. Please call this number or email us at [enter your support email address] so we can support your needs.",
    "Welcome to Nabowy Dashboard SMS Rewards! Keep your eyes peeled for your first exclusive discount, coming to you soon.",
    "Thanks for opting into text alerts for your recent order. We’ll send you a message once your order has shipped!",
    "Sorry we missed your call. If you’re looking to book an appointment you can schedule online here: nabowy.online.",
    "Thanks for your first Nabowy Dashboard purchase! We’re excited for you to try out our product. Here’s how you can set it up: nabowy.work@gmail.com",
    "Thanks for entering the Nabowy Dashboard spring sweepstakes! We’ll reach out to the winners on August 31 by noon PST."
];

export const labels = [
    "2016",
    "2017",
    "2018",
    "2019",
    "2019",
    "2020",
    "2021",
    "2022",
];