const Header = () => {
    const getNameFromLocalStorage  = localStorage.getItem("name");
    return (
        <header className="p-md-4 p-lg-4 p-1" style={{ marginLeft: "3px !important", backgroundColor: "var(--light-color)", position: "relative", borderBottom: "2px solid var(--main-color)" }}>
            <div className="container">
                <div className="d-flex align-items-center justify-content-end">
                    <div className="p-2 p-lg-0 p-md-0 d-flex d-flex-between">
                        <div className="ms-3" style={{ width: "50px", height: "50px", borderRadius: "50%" }}>
                            <img src={require(`../images/Elipse15.png`)} alt="img" style={{ width: "100%" }} />
                        </div>
                        <div className="ms-2">
                            <h6 style={{ fontSize: "13px" }} className="user-name text-white fw-bold mb-1">{getNameFromLocalStorage ? getNameFromLocalStorage : "Mahmoud Nabowy"}</h6>
                            <p style={{ fontSize: "11px" }} className="text-white-50 fw-bold">Project Manager</p>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header