import Header from "../Header/Header";
import CalendarUi from "./CalendarUi/CalendarUi";
import ChatBoot from "./ChatBoot/ChatBoot";

const Calendar = () => {
    return (
        <div className="page-sections pb-3  text-white"
        style={{ backgroundColor: "var(--bg-color)" }}>
        <Header />
            <div className="container">
                <div className="row pt-3 pb-3">
                    <CalendarUi />
                    <ChatBoot />
                </div>
            </div>
        </div>
    )
}

export default Calendar;