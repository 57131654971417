import { useEffect } from "react";
import { chatLogic } from "../../utilities/utilities";
import ProfileBox from "./ProfileBox";
import ConversationInput from "./ConversationInput";
import ChatMessage from "./ChatMessage";

const ChatBoot = () => {

    useEffect(() => {
        chatLogic();
    });
    return (
        <div className="col-lg-4 p-2 ">
            <div style={{ backgroundColor: 'var(--off-dark)', width: '100%', borderRadius: '10px' }} className="p-2">
                <div className="chat-head mb-4 d-flex d-flex-between p-2 user-select-none">
                    <span style={{ cursor: 'pointer', width: '30px', height: '30px', borderRadius: '50%', backgroundColor: 'var(--main-color)' }} className="d-flex-center ms-1">
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                    <div>
                        <span style={{ cursor: 'pointer' }} className="me-3"><i className="fa-solid fa-video"></i></span>
                        <span style={{ cursor: 'pointer' }} className="me-3"><i className="fa-solid fa-phone"></i></span>
                        <span style={{ cursor: 'pointer' }} className="me-3"><i className="fa-solid fa-ellipsis-vertical"></i></span>
                    </div>
                </div>
                <ProfileBox />
                <div className="full-chat pb-3" style={{ maxHeight: "532px", overflowY: "auto", overflowX: "hidden" }}>
                    <h6 className="text-center fw-bold position-relative mb-3">Full Chat</h6>
                    <ChatMessage />
                </div>
                <ConversationInput />
            </div>
        </div>
    )
}

export default ChatBoot;