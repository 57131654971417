import { images } from "../../../utilities/data";

const UserTableRow = ({row}) => {
    return (
        <tr className={row.role.toLowerCase()}>
            <td className="p-2" style={{ borderLeft: "1px solid var(--second-color)" }}>
                <img
                    src={images[Math.floor(Math.random() * images.length)]} alt={row.name} style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight: "5px !important" }} />
                Mahmoud I-Nabowy
            </td>
            <td className="p-2" style={{ borderLeft: "1px solid var(--second-color)" }}>
                {row.user}
            </td>
            <td className="p-2" style={{ borderLeft: "1px solid var(--second-color)" }}>
                {row.role}
            </td>
            <td className="p-2" style={{ borderLeft: "1px solid var(--second-color)" }}>
                {row.phone}
            </td>
            <td className="p-2" style={{ borderLeft: "1px solid var(--second-color)" }}>
                {row.data}
            </td>
            <td className="p-2" style={{ borderLeft: "1px solid var(--second-color)" }}>
                {row.status}
            </td>
        </tr>
    )
}

export default UserTableRow;