const WidgetControl = () => {
    return (
        <div id="widgest" className="p-3 mb-3 setting-media"
            style={{ minHeight: 372, borderRadius: 10, backgroundColor: 'var(--main-color)', width: 'calc(97% / 3)' }}>
            <h3 className="fw-bolder mb-2">Widgest Control</h3>
            <p style={{ fontSize: 11 }} className="text-white-50">Show/Hide Widgest</p>
            <div className="mt-2 mb-2">
                <div className="d-flex align-items-center mt-3 mb-3">
                    <input type="checkbox" defaultChecked id="checkBoxOne" />
                    <label style={{ cursor: 'pointer', fontWeight: 600, fontSize: 13 }} htmlFor="checkBoxOne" className="ms-2 text-white-50">Quick Draft</label>
                </div>
                <div className="d-flex align-items-center mt-3 mb-3">
                    <input type="checkbox" defaultChecked id="checkBoxTwo" />
                    <label style={{ cursor: 'pointer', fontWeight: 600, fontSize: 13 }} htmlFor="checkBoxTwo" className="ms-2 text-white-50">Yearly Targets</label>
                </div>
                <div className="d-flex align-items-center mt-3 mb-3">
                    <input type="checkbox" defaultChecked id="checkBoxThree" />
                    <label style={{ cursor: 'pointer', fontWeight: 600, fontSize: 13 }} htmlFor="checkBoxThree" className="ms-2 text-white-50">Tickets Statistics</label>
                </div>
                <div className="d-flex align-items-center mt-3 mb-3">
                    <input type="checkbox" defaultChecked id="checkBoxFour" />
                    <label style={{ cursor: 'pointer', fontWeight: 600, fontSize: 13 }} htmlFor="checkBoxFour" className="ms-2 text-white-50">Latest News</label>
                </div>
                <div className="d-flex align-items-center mt-3 mb-3">
                    <input type="checkbox" id="checkBoxFive" />
                    <label style={{ cursor: 'pointer', fontWeight: 600, fontSize: 13 }} htmlFor="checkBoxFive" className="ms-2 text-white-50">Latest Tasks</label>
                </div>
                <div className="d-flex align-items-center mt-3 mb-3">
                    <input type="checkbox" defaultChecked id="checkBoxSix" />
                    <label style={{ cursor: 'pointer', fontWeight: 600, fontSize: 13 }} htmlFor="checkBoxSix" className="ms-2 text-white-50">Top Search Items</label>
                </div>
                <div className="d-flex align-items-center mt-3 mb-3">
                    <input type="checkbox" defaultChecked id="checkBoxSeven" />
                    <label style={{ cursor: 'pointer', fontWeight: 600, fontSize: 13 }} htmlFor="checkBoxSeven" className="ms-2 text-white-50">Last Changes</label>
                </div>
            </div>
        </div>
    )
}
export default WidgetControl;