import RoleSelectionForm from "./RoleSelectionForm";

const InvitePeople = () => {
    return (
        <div className="invite-form text-white w-100" style={{ transition: 'var(--trasnition)', opacity: 0, visibility: 'hidden', backgroundColor: 'var(--bg-color)', border: '2px solid #14161a', zIndex: 111, width: '40%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%) scale(0)' }}> <div className="d-flex-between d-flex p-4">
            <h2 className="fw-bold" style={{ fontSize: 15 }}>Invite Member</h2>
            <span className="cansel-intvite-btn"><i className="fa-solid fa-xmark" style={{ fontSize: 25, cursor: 'pointer' }}></i></span>
        </div>
            <div className="p-4 d-flex-between d-flex">
                <div style={{ width: '49%' }}>
                    <p className="text-center fw-bold mb-1">First Name</p>
                    <input type="text" className="p-3" id="first_Name" style={{ color: 'white', width: '100%', border: '1px solid', outline: 'none', backgroundColor: 'var(--bg-color)' }} />
                </div>
                <div style={{ width: '49%' }}>
                    <p className="text-center fw-bold mb-1">Last Name</p>
                    <input type="text" className="p-3" id="last_Name" style={{ color: 'white', width: '100%', border: '1px solid', outline: 'none', backgroundColor: 'var(--bg-color)' }} />
                </div>
            </div>
            <div className="p-4">
                <p className="fw-bold mb-1">Email Address</p>
                <input type="text" className="p-3" id="email_add" style={{ color: "white", width: "100%", border: "1px solid", outline: "none", backgroundColor: "var(--bg-color)" }} />
            </div>
                <RoleSelectionForm />
            <div className="d-flex mb-3 align-items-center justify-content-end" style={{ backgroundColor: "var(--bg-color)" }} >     <div className="main-btn cansel me-3" style={{ backgroundColor: "#404148", width: "fit-content" }}     >         Cansel     </div>     <div className="main-btn invite" style={{ backgroundColor: "#0362ff", marginRight: "20px !important", width: "fit-content" }}     >         Invite     </div> </div>
        </div>
    )
}

export default InvitePeople;