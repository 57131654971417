const LatestTasksItem = ({ task }) => {
    return (
        <div className="mb-3" style={{ borderBottom: '1px solid #333' }}>
            <h4 className="fw-bold mb-2" style={{ fontSize: '13px' }}>
                {task.taskName}
            </h4>
            <p className="fw-bold mb-3 text-white-50" style={{ fontSize: '10px' }}>
                {task.taskDesc}
            </p>
        </div>
    );
}

export default LatestTasksItem;