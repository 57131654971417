const SiteControlPanel = () => {
    return (
        <div id="site_control" className="p-3 mb-3 setting-media"
            style={{ minHeight: 372, borderRadius: 10, backgroundColor: 'var(--main-color)', width: 'calc(97% / 3)' }}>
            <h3 className="fw-bolder mb-2">Site Control</h3>
            <p style={{ fontSize: 11 }} className="fw-bold text-white-50">Control The Website If There is Maintenace</p>
            <div className="d-flex-between d-flex mt-4 mb-4">
                <div>
                    <p style={{ fontSize: 14 }} className="mb-2">Website Control</p>
                    <p style={{ fontSize: 14 }} className="text-white-50">Open/Close Website And Type The Reson</p>
                </div>
                <label>
                    <input className="toggle-checkbox" type="checkbox" />
                    <div className="toggle-switch"></div>
                </label>
            </div>
            <textarea className="p-2 pt-3 text-white-50 fw-bold"
                style={{ fontSize: 12, border: 'none', backgroundColor: 'var(--second-color)', outline: 'none' }} name=""
                id="" cols="50" rows="11" placeholder="Close Message Contant"></textarea>
        </div>
    )
}

export default SiteControlPanel;