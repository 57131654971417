import {Routes , Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Sidebar from "./Components/Sidebar/Sidebar";
import "./index.css"
import Setting from "./Components/Setting/Setting";
import Calendar from "./Components/Calendar/Calendar";
import Files from "./Components/Files/Files";
import Contact from "./Components/Contact/Contact";
function App() {
  return (
    <div className="d-flex">
        <Sidebar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/files" element={<Files />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
    </div>
  );
}

export default App;
