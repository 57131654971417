import { images } from "../../../utilities/data";

const ViewProjectSection = () => {

    return (
        <div className="mt-3 mb-3">
            <h6 className="text-white-50 fw-bold" style={{ fontSize: "11px" }}>6 days left</h6>
            <div className="d-flex-between d-flex">
                <div style={{ width: "80%", backgroundColor: "var(--off-dark)", height: "5px", borderRadius: "3px" }}>
                    <span style={{ display: "block", width: "78%", height: "5px", borderRadius: "inherit", backgroundColor: "#777" }}></span>
                </div>
                <span className="text-white fw-bold" style={{ fontSize: "12px" }}>78%</span>
            </div>
            <div className="mt-3 mb-3 d-flex d-flex-between">
                <div className="d-flex d-flex d-flex-between">
                    <div className="me-3">
                        <img src={images[Math.floor(Math.random() * images.length)]} alt="img" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                        <img src={images[Math.floor(Math.random() * images.length)]} alt="img" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                        <img src={images[Math.floor(Math.random() * images.length)]} alt="img" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                        <img src={images[Math.floor(Math.random() * images.length)]} alt="img" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                    </div>
                    <p className="fw-bold text-white" style={{ fontSize: '13px' }}> T:Will </p>
                </div>
                <button style={{ padding: '10px 20px', fontWeight: 'bold', borderRadius: '8px', border: 'none', outline: 'none', fontSize: '13px', backgroundColor: '#0362ff' }}>
                    <a target="_blank" href="https://web.nabowy.online" className="text-white">View Project</a>
                </button>
            </div>
        </div>
    )
}
export default ViewProjectSection;