import Chart from 'chart.js/auto';
import { useEffect } from 'react';
import { labels } from '../../../../utilities/data';
const ChartUi = () => {
    useEffect(() => {
        const chartContainer = document.querySelector("#overallProgress");
        const canvas = document.querySelector("#myChart");
        const ctx = canvas.getContext("2d");
        let gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, "rgba(58 ,123 ,231 , 1)");
        gradient.addColorStop(1, "rgba(0 ,210 ,255 , 0.3)");
        let delayed;
        const data = {
            labels,
            datasets: [{
                data: [211, 326, 165, 350, 420, 370, 500, 375, 115],
                label: "Nabowy dashboard",
                fill: true,
                backgroundColor: gradient,
                borderColor: "#eee",
                pointBackgroundColor: "#000",
                tension: 0.7,
            }]
        };

        const config = {
            type: "line",
            data: data,
            options: {
                radius: 2,
                hitRadius: 150,
                responsive: true,
                animation: {
                    onComplete: () => (
                        {
                            delayed: true
                        }
                    ),
                    delay: (context) => {
                        let delay = 0;
                        if (context.type === "data" && context.mode === "default" && !delayed) {
                            delay = context.dataIndex * 500 + context.datasetIndex * 100;
                        }
                        return delay
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            display: false // hide y-axis labels
                        }
                    }
                }
            }
        };

        let myChart = new Chart(ctx, config);

        const handleResize = () => {
            const containerWidth = chartContainer.clientWidth - 30;
            myChart.destroy();
            canvas.width = containerWidth;
            myChart = new Chart(ctx, config);
        };

        // add event listener to window object to handle resizing
        window.addEventListener("resize", handleResize);

        // cleanup function
        return () => {
            window.removeEventListener("resize", handleResize);
            myChart.destroy();
        };
    }, []);
    return (
        <canvas id="myChart"></canvas>
    )
}
export default ChartUi;