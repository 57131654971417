import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useEffect } from "react";
import { animitNum, fullscreenImg } from "../../../../utilities/utilities";
import { images } from "../../../../utilities/data";
import SlideImage from "./SlideImage";


const HomeChat = () => {
    const darwSwiperSliderUi = images.map((imgSrc, index) => <SwiperSlide key={index}><SlideImage imgSrc={imgSrc} index={index} /></SwiperSlide>);
    useEffect(() => {
        fullscreenImg(".wrapper-img img");
        animitNum();
    });
    return (
        <div className="p-3 mb-3 text-white" style={{ backgroundColor: 'var(--main-color)', userSelect: "none", borderRadius: '10px' }}>
            <div className="d-flex d-flex-between">
                <h1 className="text-white fw-bold" style={{ fontSize: '20px' }}>Chat</h1>
                <p><a href="#" style={{ color: '#5d513a', fontWeight: 'bold', fontSize: '13px' }}>See All</a></p>
            </div>
            <div>
                <Swiper grabCursor={true} slidesPerView={10} loop={true} spaceBetween={10} className="mySwiper" >
                    {darwSwiperSliderUi}
                </Swiper>
            </div>
        </div>
    )
}

export default HomeChat;