const RoleSelectionForm = () => {
    return (
        <div className="p-4 role-wrapper">
            <h1 className="mb-4 fw-bold" style={{ fontSize: "16px" }}>Role</h1>
            <div className="reviewer mb-4 d-flex align-items-start mt-2">
                <input type="radio" name="role" id="Reviewer" defaultChecked />
                <label htmlFor="Reviewer" className="fw-bold ms-2">
                    <h2 className="mb-2 fw-bold" style={{ fontSize: "14px" }}>    Reviewer</h2>
                    <p className="text-white-50" style={{ fontSize: "12px" }}>
                        Reviewers Can't edit methodology, document requirements, edit award information, manage users. Reviewers can only see applicants that have been assigned to them to review.
                    </p>
                </label>
            </div>
            <div className="admin mb-4 d-flex align-items-start mt-2">
                <input type="radio" name="role" id="administrator" />
                <label htmlFor="administrator" className="fw-bold ms-2">
                    <h2 className="mb-2 fw-bold" style={{ fontSize: "14px" }}>    Administrator</h2>
                    <p className="text-white-50" style={{ fontSize: "12px" }}>    Administrators Can do everything,    including managing users and deleting current administrators    been assigned to them to review.</p>
                </label>
            </div>
            <div className="p-4 mb-2">
                <div className="d-flex-between d-flex pe-2 ps-2">
                    <h4 className="fw-bold" style={{ fontSize: "13px" }}>Custom Message</h4>
                    <p style={{ fontSize: "14px" }} className="text-white-50">    Optional</p>
                </div>
            </div>
            <input type="text" style={{ backgroundColor: "var(--bg-color)", borderRadius: "3px", width: "100%", height: "70px", border: "1px solid", outline: "none", fontSize: "14px" }} className="p-2 fw-bold text-white-50" placeholder="Optionally, provide additional custom message...." />
        </div>
    )
}

export default RoleSelectionForm;