const UserEntry = ({user}) => {
    return (
        <div className="p-2 d-flex-between d-flex text-white" style={{ borderBottom: '1px solid #222', backgroundColor: 'var(--bg-color)' }}>
            <div className="d-flex align-items-center fw-bold">
                <img src={user.imgUrl}
                    className="me-2" alt="img" style={{ width: '35px', height: '35px', borderRadius: '50%' }} />
                <div>
                    <p style={{ fontSize: '15px' }}>{user.name}</p>
                    <p className="text-white-50" style={{ fontSize: '12px' }}>{user.email}</p>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div className="p-2 fw-bold pe-4 ps-4 me-3"
                    style={{ fontSize: '13px', backgroundColor: 'var(--off-dark)', borderRadius: '7px', userSelect: 'none' }}>
                    {user.role}
                </div>
                <span className="trash"><i className="fa-regular fa-trash-can me-2" style={{ cursor: 'pointer' }}></i></span>
            </div>
        </div>
    )
}

export default UserEntry;