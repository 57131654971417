import { useEffect } from "react";
import {tableLogic } from "../../../utilities/utilities";
import {  tableRowData } from "../../../utilities/data";
import UserTableRow from "./UserTableRow";
import SortingOptions from "./SortingOptions";

const Table = () => {

    const darwTableRow = tableRowData.map((row, index) => <UserTableRow key={index}  row={row}/>);
    useEffect(() => {
        tableLogic();
    }, []);
    return (
        <div className="table mt-3 mb-3 p-2 text-white-50">
            <SortingOptions />
            <table className="w-100" style={{ backgroundColor: 'var(--main-color)', border: '1px solid var(--second-color)', fontWeight: 'bold', fontSize: '11px' }}>
                <thead>
                    <tr className="p-2">
                        <td className="p-2" style={{ borderLeft: '1px solid var(--second-color)' }}>Name</td>
                        <td className="p-2" style={{ borderLeft: '1px solid var(--second-color)' }}>User</td>
                        <td className="p-2" style={{ borderLeft: '1px solid var(--second-color)' }}>Role</td>
                        <td className="p-2" style={{ borderLeft: '1px solid var(--second-color)' }}>PHONE</td>
                        <td className="p-2" style={{ borderLeft: '1px solid var(--second-color)' }}>EXPIRE DATA</td>
                        <td className="p-2" style={{ borderLeft: '1px solid var(--second-color)' }}>Status</td>
                    </tr>
                </thead>
                <tbody>
                    {darwTableRow}
                </tbody>
            </table>
        </div>
    )
}

export default Table;