const SortingOptions = () => {
    return (
        <div className="p-2 mb-3 d-flex d-flex-between" style={{ backgroundColor: 'var(--main-color)', borderRadius: '5px' }}>
            <h2 className="d-flex d-flex-between fw-bold" style={{ fontSize: '15px' }}>
                <span className="text-white"><i className="fa-solid fa-calendar-days me-3"></i></span>
                Dec 2022
            </h2>
            <div className="d-flex justify-content-center flex-wrap">
                <select id="filter_Select" className="fw-bold p-1 me-3 text-white-50" style={{ border: '1px solid #333', backgroundColor: 'var(--main-color)', cursor: 'pointer' }}>
                    <option style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"none"} disabled>Filter</option>
                    <option className="text-white" style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"All"}>All</option>
                    <option className="text-white" style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"admin"}>Admin</option>
                    <option className="text-white" style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"partner"}>Partner</option>
                </select>
                <select id="sort" className="fw-bold p-1 text-white-50" style={{ border: '1px solid #333', backgroundColor: 'var(--main-color)', cursor: 'pointer' }}>
                    <option style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"none"} disabled>Sort By</option>
                    <option className="text-white" style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"admin-partner"}>Admin - Partner</option>
                    <option className="text-white" style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"partner-admin"}>Partner - Admin</option>
                    <option className="text-white" style={{ fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }} value={"default"}>Default</option>
                </select>
            </div>
        </div>
    )
}

export default SortingOptions;