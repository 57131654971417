import { useEffect } from "react";
import { HomeSectionLogic } from "../../../utilities/utilities";
import TaskInputPanel from "./TaskInputPanel";

const NewTaskForm = () => {
    useEffect(() => {
        HomeSectionLogic();
    } , []);
    return (
        <div className="addNewTask text-white" style={{ transition: "var(--trasnition)", opacity: 0, visibility: "hidden", backgroundColor: "var(--bg-color)", border: "2px solid #14161a", zIndex: 111, position: "absolute", transform: " scale(0)" }}>
            <div className="d-flex-between d-flex w-100 p-4">
                <h2 className="fw-bold" style={{ fontSize: "15px" }}>Add New Task</h2>
                <span className="closeNewTaskButton d-flex-center">
                    <i className="fa-solid fa-xmark" style={{ fontSize: "25px", cursor: "pointer" }}></i>
                </span>
            </div>
                <TaskInputPanel />
            <div className="d-flex mb-3 align-items-center justify-content-end" style={{ backgroundColor: "var(--bg-color)" }}>
                <div className="main-btn closeNewTaskButton me-3" style={{ backgroundColor: "#404148", width: "fit-content" }}> Cansel</div>
                <div className="main-btn addNewTaskButton" style={{ backgroundColor: "#0362ff", width: "fit-content" }}> Add</div>
            </div>
        </div>
    )
}

export default NewTaskForm;