const EventCalendar = () => {
    const dayNames = ["Man", "Tue", "Wed", "Thu", "Fri", "Set", "Sun"];
    const renderDayName = dayNames.map((day, index) => {
        return <p key={index} style={{ width: 'calc(100% / 7)' }} className="fw-bold p-2">{day}</p>
    });
    return (
        <div style={{ backgroundColor: 'var(--bg-color)', borderRadius: '10px' }} className="p-2">
            <div className="calendar-head fw-bold p-2 mb-2 d-flex d-flex-between" style={{ borderBottom: '1px solid var(--second-color)' }}>
                <p>
                    <span className="month me-2"></span>
                    <span className="year"></span>
                </p>
                <div>
                    <p className="left-angel me-3 d-inline-flex justify-content-center align-items-center" style={{ cursor: 'pointer', width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#333' }}>
                        <i className="fa-solid fa-angle-left" style={{ color: 'white' }}></i>
                    </p>
                    <p className="right-angel d-inline-flex justify-content-center align-items-center" style={{ cursor: 'pointer', width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#333' }}>
                        <i className="fa-solid fa-angle-right" style={{ color: 'white' }}></i>
                    </p>
                </div>
            </div>
            <div className="days-wrapper text-center">
                <div className="d-flex-between d-flex">{renderDayName}</div>
                <div className="days d-flex align-items-center flex-wrap"></div>
            </div>
        </div>
    )
}

export default EventCalendar;