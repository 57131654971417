const BackupPlanner = () => {
    return (
        <div id="backup" className="p-3 mb-3 setting-media" style={{ minHeight: 372, borderRadius: 10, backgroundColor: 'var(--main-color)', width: 'calc(97% / 3)' }}>
            <h3 className="fw-bolder mb-2">Backup Manager</h3>
            <p style={{ fontSize: 11 }} className="text-white-50">Control Backup Time And Location</p>
            <div className="backup-control mt-3 mb-3" style={{ borderBottom: '2px dashed #777' }}>
                <div className="date mb-4 d-flex align-items-center">
                    <input type="radio" name="time" id="daily" defaultChecked />
                    <label htmlFor="daily" className="text-white-50 fw-bold">Daily</label>
                </div>
                <div className="date mb-4 d-flex align-items-center">
                    <input type="radio" name="time" id="weekly" />
                    <label htmlFor="weekly" className="text-white-50 fw-bold">Weekly</label>
                </div>
                <div className="date mb-4 d-flex align-items-center ">
                    <input type="radio" name="time" id="monthly" />
                    <label htmlFor="monthly" className="text-white-50 fw-bold">Monthly</label>
                </div>
            </div>
            <div className="d-flex-between d-flex">
                <div className="p-3 border d-flex-center flex-column" style={{ borderRadius: 10, width: 'calc(96% / 3)' }}>
                    <i className="fa-solid fa-server mb-3"></i>
                    <p>Megaman</p>
                </div>
                <div className="p-3 border d-flex-center flex-column" style={{ borderRadius: 10, borderColor: '#0075ff !important', width: 'calc(96% / 3)' }}>
                    <i className="fa-solid fa-server mb-3"></i>
                    <p>Zero</p>
                </div>
                <div className="p-3 border d-flex-center flex-column" style={{ borderRadius: 10, width: 'calc(96% / 3)' }}>
                    <i className="fa-solid fa-server mb-3"></i>
                    <p>Sigma</p>
                </div>
            </div>
        </div>
    )
}

export default BackupPlanner;