const DeleteMessage = () => {
    return (
        <div className="delete-member text-white p-0"
            style={{ transition: "var(--trasnition)", visibility: "hidden", opacity: 0, backgroundColor: "#333", width: "30%", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%) scale(0)" }}>
            <div className="d-flex-between d-flex p-3" style={{ backgroundColor: "var(--main-color)" }}>
                <h2 className="fw-bold" style={{ fontSize: "15px" }}>Remove Member</h2>
                <span className="member-btn">
                    <i className="fa-solid fa-xmark" style={{ fontSize: "25px", cursor: "pointer" }}></i>
                </span>
            </div>
            <div className="p-3 pt-4 pb-4 text-center text-white-50" style={{ backgroundColor: "var(--bg-color)", border: "2px solid #27292d" }}>
                <p>
                    You are about to remove a member witch will not allow this Person to login into your organization anymore
                </p>
            </div>
            <div className="p-2 d-flex justify-content-end align-items-center" style={{ backgroundColor: "var(--main-color)" }}>
                <div className="main-btn me-2" style={{ width: "fit-content !important" }}>
                    Keep Member
                </div>
                <div className="main-btn" style={{ width: "fit-content !important", backgroundColor: "#ca5374" }}>
                    Remove Member
                </div>
            </div>
        </div>
    )
}

export default DeleteMessage;