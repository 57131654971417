const SocialMediaInfo = () => {
    return (
        <div id="social_info" className="p-3 mb-3 setting-media"
            style={{ minHeight: 372, borderRadius: 10, backgroundColor: 'var(--main-color)', width: 'calc(97% / 3)' }}>
            <h3 className="fw-bolder mb-2">Social Info</h3>
            <p style={{ fontSize: 11 }} className="text-white-50">Social Media Information</p>
            <div className="ps-3 mt-4 mb-4 fw-bold d-flex align-items-center"
                style={{ border: '2px solid #777', borderRadius: 5, backgroundColor: 'var(--second-color)' }}>
                <div style={{ minWidth: 28, borderRight: '2px solid #777' }} className="pe-2">
                    <a href="https://twitter.com/M_Nabowy" target="_blank"><i className="fab fa-twitter fs-6"></i></a>
                </div>
                <a href="https://twitter.com/M_Nabowy" target="_blank">
                    <div className="p-2 text-white-50" style={{ fontSize: 11, opacity: 0.5 }}>Twitter Link</div>
                </a>
            </div>
            <div className="ps-3 mb-4 fw-bold d-flex align-items-center"
                style={{ border: '2px solid #777', borderRadius: 5, backgroundColor: 'var(--second-color)' }}>
                <div style={{ minWidth: 28, borderRight: '2px solid #777' }} className="pe-2">
                    <a href="https://www.facebook.com/elngmadle" target="_blank"><i className="fab fa-facebook-f fs-6"></i></a>
                </div>
                <a href="https://www.facebook.com/elngmadle" target="_blank">
                    <div className="p-2 text-white-50" style={{ fontSize: 11, opacity: 0.5 }}>Facebook Link</div>
                </a>
            </div>
            <div className="ps-3 mb-4 fw-bold d-flex align-items-center"
                style={{ border: '2px solid #777', borderRadius: 5, backgroundColor: 'var(--second-color)' }}>
                <div style={{ minWidth: 28, borderRight: '2px solid #777' }} className="pe-2">
                    <a href="https://www.linkedin.com/in/mahmoud-nabowy-3b2134272/" target="_blank"><i className="fab fa-linkedin fs-6"></i></a>
                </div>
                <a href="https://www.linkedin.com/in/mahmoud-nabowy-3b2134272/" target="_blank">
                    <div className="p-2 text-white-50" style={{ fontSize: 11, opacity: 0.5 }}>Linkedin Link</div>
                </a>
            </div>
            <div className="ps-3 mb-4 fw-bold d-flex align-items-center"
                style={{ border: '2px solid #777', borderRadius: 5, backgroundColor: 'var(--second-color)' }}>
                <div style={{ minWidth: 28, borderRight: '2px solid #777' }} className="pe-2">
                    <a href="https://www.youtube.com/watch?v=vKxBy8XygJg" target="_blank"><i className="fab fa-youtube fs-6"></i></a>
                </div>
                <a href="https://www.youtube.com/watch?v=vKxBy8XygJg" target="_blank">
                    <div className="p-2 text-white-50" style={{ fontSize: 11, opacity: 0.5 }}>Youtube Link</div>
                </a>
            </div>
        </div>
    )
}

export default SocialMediaInfo;