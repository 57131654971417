import { Fragment, useEffect } from "react";
import { GET_USER_NAME } from "../../utilities/utilities";
import SiteControlPanel from "./SiteControlPanel";
import PersonalInfoForm from "./PersonalInfoForm";
import AccountSecurityPanel from "./AccountSecurityPanel";
import SocialMediaInfo from "./SocialMediaInfo";
import WidgetControl from "./WidgetControl";
import BackupPlanner from "./BackupPlanner";

const SettingSecurity = () => {
    useEffect(() => {
        GET_USER_NAME();
    })
    return (
        <Fragment>
            <div className="d-flex-between d-flex flex-wrap text-white p-3">
                <SiteControlPanel />
                <PersonalInfoForm />
                <AccountSecurityPanel />
                <SocialMediaInfo />
                <WidgetControl />
                <BackupPlanner />
            </div>
        </Fragment>
    )
}

export default SettingSecurity;